import { Col, Container, Form, Row } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { NumericFormat } from "react-number-format";

export default function EventTikcetForm({ticketNumber, setTicketNumber, ticketNumberIsValid, eventLocal, setEventLocal,
        setEventLocalMapsLink, price, setPrice, seat, setSeat, section, setSection, gate, setGate,
        eventLocalIsValid,
        eventLocalMapsLinkIsValid, priceIsValid, isEditable,
        row, setRow
        }) {


    return (
        <> 
            <div className="form-group">
                        <Form.Label>
                            Número do ticket<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Número do ticket"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Número do ticket" onChange={(e) => setTicketNumber(e.target.value)} 
                        value={ticketNumber} isInvalid={ticketNumberIsValid == null ? false : !ticketNumberIsValid}
                        isValid={ticketNumberIsValid == null ? false : ticketNumberIsValid}
                        disabled={!isEditable}
                        />
                        <Form.Control.Feedback type="invalid">Número de ticket inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                            Nome do local do evento<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome do local do evento"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Nome do local do evento"
                        value={eventLocal}
                        onChange={(e) => setEventLocal(e.target.value)}
                        isValid={eventLocalIsValid == null ? false : eventLocalIsValid}
                        isInvalid={eventLocalIsValid == null ? false : !eventLocalIsValid}
                        disabled={!isEditable}
                        />
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                              Link do local do evento<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Link do local do evento"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Link do local do evento" 
                                        onChange={(e) => setEventLocalMapsLink(e.target.value)}
                                        isValid={eventLocalMapsLinkIsValid == null ? false : eventLocalMapsLinkIsValid}
                                        isInvalid={eventLocalMapsLinkIsValid == null ? false : !eventLocalMapsLinkIsValid}
                                        disabled={!isEditable}
                                />
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>

            

            <Container>
                        <Row className="align-items-center mb-3">
                            <Col style={{minWidth: "50%" }}>
                            <div className="form-group">
                                        <Form.Label>
                                                Portão{' '}
                                                        {/* <FaInfoCircle
                                                        style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                                        title="Nome do portador, aparecerá no ticket"
                                                        /> */}
                                                </Form.Label>

                                            <Form.Control
                                            type="text"
                                            placeholder="Plataforma"
                                            style={{ width: "100%" }}
                                            value={gate}
                                            onChange={(e) => setGate(e.target.value)}
                                            disabled={!isEditable}
                                        //     onChange={(e) => setPlatform(e.target.value)}
                                        //     isInvalid={platformIsValid == null ? false : !platformIsValid}
                                        //     isValid={platformIsValid == null ? false : platformIsValid}
                                        //     value={platform}

                                            />
                                </div>
                            </Col>

                            <Col style={{minWidth: "50%"  }}>

                            <div className="form-group">
                            <Form.Label>
                                    Seção{' '}
                                            {/* <FaInfoCircle
                                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                            title="Nome do portador, aparecerá no ticket"
                                            /> */}
                                    </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Assento"
                                style={{ width: "100%" }}
                                value={section}
                                onChange={(e) => setSection(e.target.value)}
                                disabled={!isEditable}
                                // onChange={(e) => setSeat(e.target.value)}

                                // isInvalid={seatIsValid == null ? false : !seatIsValid}
                                // isValid={seatIsValid == null ? false : seatIsValid}
                                // value={seat}
                                />
                                </div>
                            </Col>
                            <Col style={{minWidth: "50%"  }}>


                            <div className="form-group">
                            <Form.Label>
                                    Assento{' '}
                                            {/* <FaInfoCircle
                                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                            title="Nome do portador, aparecerá no ticket"
                                            /> */}
                                    </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Assento"
                                style={{ width: "100%" }}
                                value={seat}
                                onChange={(e) => setSeat(e.target.value)}
                                disabled={!isEditable}
                                // onChange={(e) => setSeat(e.target.value)}

                                // isInvalid={seatIsValid == null ? false : !seatIsValid}
                                // isValid={seatIsValid == null ? false : seatIsValid}
                                // value={seat}
                                />
                                </div>

                            </Col>
                            <Col style={{minWidth: "50%"  }}>


                            <div className="form-group">
                            <Form.Label>
                                    Fileira{' '}
                                            {/* <FaInfoCircle
                                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                            title="Nome do portador, aparecerá no ticket"
                                            /> */}
                                    </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Fileira"
                                style={{ width: "100%" }}
                                value={row}
                                onChange={(e) => setRow(e.target.value)}
                                disabled={!isEditable}
                                // onChange={(e) => setSeat(e.target.value)}

                                // isInvalid={seatIsValid == null ? false : !seatIsValid}
                                // isValid={seatIsValid == null ? false : seatIsValid}
                                // value={seat}
                                />
                                </div>

                            </Col>
                            <Col style={{minWidth: "100%"  }}>
                            
                            <div className="form-group">
                        <Form.Label>
                        Preço<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Preço do ingresso"
                                />
                        </Form.Label>

                        <Form.Control as={NumericFormat}  
                                            placeholder="Valor"
                                            prefix="R$ "
                                            // fixedDecimalLength={2}
                                            decimalScale={2}
                                            decimalsLimit={2}
                                            decimalSeparator=","
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            allowLeadingZeros
                                            // decimalSeparator=","
                                            // groupSeparator="."
                                            // onValueChange={(value, name, values) => console.log(value, name, values)}
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            isValid={priceIsValid === null ? false : priceIsValid}
                                            isInvalid={priceIsValid === null ? false : !priceIsValid}
                                            disabled={!isEditable}
                                            />
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
                            
                            </Col>

                        </Row>
            </Container>
            
        </>
    )
}