import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTicketByExternalIdService } from "../../Services/ticketService";
import TransportTicket from "../../components/qrTemplates/transportTicket";
import LoyaltyCard from "../../components/qrTemplates/customerCard";
import EventTicket from "../../components/qrTemplates/eventTicket";

export default function ExternalViewTicketPage() {

    const {externalId} = useParams();
    const [ticket, setTicket] = useState(null);
    const [template, setTemplate] = useState(null)

    const getTicketById = async  () =>{


        const response = await getTicketByExternalIdService({externalId: externalId})

        switch (response.statusCode) {
            case 200:
    
            //   toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});
              
            //  console.log(response.response.ticket);
             setTicket(response.response.ticket);
    
    
    
              break;
            case 404:
            //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
             break;
            default:
            //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
              break;
    }
}


const TemplateTicket = () =>{

        
    switch (ticket.template.typeTemplate) {
      case 1:
        return <TransportTicket typeCode={ticket.template.typeCode} 
                                issuerName={ticket.template.issuerName}
                                codeValue={ticket.qrCodeValue}
                                metadata={ticket.metadata}
                                companyLogo={ticket.template.logoUrl}
                                origin={ticket.originName}
                                destination={ticket.destinationName}
                                originDate={new Date(ticket.timeInterval.startDate)}
                                destinationDate={new Date(ticket.timeInterval.endDate)}
                                ticketNumber={ticket.ticketNumber}
                                passenger={ticket.holderName}
                                backgroundColor={ticket.template.backgroundColor}
                                googleJwt={ticket.jwt}
                                originCode={ticket.originCode}
                                destinationCode={ticket.destinationCode}
                                platform={ticket.platform}
                                seat={ticket.seat}
                                isExtern={true}
                                />
                                break;

    case 2:
      console.log(ticket);
       return  <EventTicket issuerName={ticket.template.eventName} 
                     eventName={ticket.template.eventName} 
                      eventDate={ new Date(ticket.template.timeInterval.startDate)}
                      metadata={ticket.metadata}
                      identifier={ticket.ticketNumber}
                      userName={ticket.holderName}
                      companyLogo={ticket.template.logoUrl}
                      backgroundColor={ticket.template.backgroundColor}
                      typeCode={ticket.template.typeCode}
                      googleJwt={ticket.jwt}
                      codeValue={ticket.qrCodeValue}
                      gate={ticket.gate}
                      section={ticket.section}
                      row={ticket.row}
                      seat={ticket.seat}
                      isExtern={true}
        />
    case 3:
        return <LoyaltyCard issuerName={ticket.template.issuerName} 
                            backgroundColor={ticket.template.backgroundColor}
                            promoName={ticket.template.promoName}
                            metadata={ticket.metadata}
                            userName={ticket.holderName}
                            companyLogo={ticket.template.logoUrl}
                            codeValue={ticket.qrCodeValue}
                            typeCode={ticket.template.typeCode}
                            googleJwt={ticket.jwt}
                            nameLabel={ticket.template.nameLabel}
                            isExtern={true}
                            />


      default:
        
        break;
    }
  }


    useEffect(() => {
    
        getTicketById();
        
      }, [])

    return (
        <>
            <div style={{alignItems: "center", justifyContent: "center", marginTop: "10px"}}>
                    {ticket && TemplateTicket()}
            </div>
        </>
    )
}