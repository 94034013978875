import { getItemStorage } from '../utils/storage';
import api from './api';
import { useRefreshToken } from './refreshToken';

export const createTemplateService = async ({ logo, banner, issuerName, typeCode , typeTemplate, backgroundColor, templateName, organizationId, 
      eventName, startDate, endDate, promoName, nameLabel}) => {
    try {


      var data = {
        "issuerName": issuerName,
        "backgroundColor": backgroundColor,
        "typeCode": typeCode,
        "typeTemplate": typeTemplate,
        "templateName": templateName,
        "organizationId": organizationId,
        "eventName": eventName,
        "startDate": startDate === null || startDate === "" ? null : startDate.toISOString(),
        "endDate": endDate === null || endDate === ""? null: endDate.toISOString(),
        "promoName": promoName,
        "nameLabel": nameLabel
      }



      for (var prop in data) {
        // Verifica se a propriedade tem valor nulo
        if (data[prop] === null || data[prop] === "") {
          // Remove a propriedade do objeto
          delete data[prop];
        }
      }

      console.log(data)
      const form = new FormData();
      form.append("logo", logo);
      form.append("banner", banner);
      form.append("json", JSON.stringify(data));


  
      // console.log(form.entries())
      const response = await api.post("/template", form, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 201) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await createTemplateService({ issuerName: issuerName, ticketName: templateName, backgroundColor: backgroundColor, 
          banner: banner, logo: logo, typeTemplate: typeTemplate, typeCode: typeCode, templateName: templateName, 
          organizationId: organizationId, eventName:  eventName, startDate: startDate, endDate: startDate,
          promoName: promoName, nameLabel: nameLabel});
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };



  export const listTemplatesService = async ({ organizationId }) => {
    try {


      // const response = await api.get(`/templates?organizationId=${organizationId}`);

      const response = await api.get(`/templates?organizationId=${organizationId}`, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 200) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await listTemplatesService({ organizationId: organizationId });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };

  export const getTemplateByIdService = async ({ organizationId, templateId }) => {
    try {


      const response = await api.get(`/template?organizationId=${organizationId}&templateId=${templateId}`, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 200) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await listTemplatesService({ organizationId: organizationId });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };
  

  export const getQuantityTemplatesByOrganizationService = async ({ organizationId }) => {
    try {


      // const response = await api.get(`/templates?organizationId=${organizationId}`);

      const response = await api.get(`/templates/quantity?organizationId=${organizationId}`, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 200) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await getQuantityTemplatesByOrganizationService({ organizationId: organizationId });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };