import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignupPage';
import RecoveryPasswordPage from '../pages/RecoveryPasswordPage';
import SwitchOrganizationPage from '../pages/SwitchOrganization';
import MainPage from '../pages/HomePage';
import TicketPage from '../pages/TicketPage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import RegisterOrganizationPage from '../pages/RegisterOrganizationPage';
import RegisterTemplatePage from '../pages/Templates/RegisterTemplatePage';
import ListTemplatesPage from '../pages/Templates/ListTemplatesPage';
import ViewTemplatePage from '../pages/Templates/ViewTemplatePage';
import RegisterTicketPage from '../pages/Tickets/QRCodeRegisterPage';
import ListTicketsPage from '../pages/Tickets/ListTickets';
import ExternalViewTicketPage from '../pages/Tickets/ExternalViewTicket';
import ViewTicketPage from '../pages/Tickets/ViewTicketPage';
import { ProtectedRoute } from './protectedRoutes';
import { UnprotectedRoute } from './unprotectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RoutesA = () => {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<UnprotectedRoute><LoginPage /></UnprotectedRoute>} />
        <Route path="/signup" element={<UnprotectedRoute><SignUpPage /></UnprotectedRoute>} />
        <Route path="/password-reset" element={<UnprotectedRoute><RecoveryPasswordPage /></UnprotectedRoute>} />
        <Route path="/password-change/:urlSerialized" element={<UnprotectedRoute><ChangePasswordPage /></UnprotectedRoute>} />

        <Route path="/switch-organization" element={<ProtectedRoute><SwitchOrganizationPage /></ProtectedRoute>} />
        <Route path="/" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />

        <Route path="/qrcodes" element={<ProtectedRoute><ListTicketsPage /></ProtectedRoute>} />
        <Route path="/organization/register" element={<ProtectedRoute><RegisterOrganizationPage /></ProtectedRoute>} />

        <Route path="/template/register" element={<ProtectedRoute><RegisterTemplatePage isEditable={true} /></ProtectedRoute>} />
        <Route path="/template/view/:templateId" element={<ProtectedRoute><ViewTemplatePage /></ProtectedRoute>} />
        
        <Route path="/templates" element={<ProtectedRoute><ListTemplatesPage /></ProtectedRoute>} />
        <Route path="/ticket/register" element={<ProtectedRoute><RegisterTicketPage /></ProtectedRoute>} />
        <Route path="/ticket/:externalId" element={<ExternalViewTicketPage />} />
        <Route path="/ticketview/:ticketId" element={<ProtectedRoute><ViewTicketPage /></ProtectedRoute>} />
      </Routes>

      <ToastContainer autoClose={5000} theme="light" />
    </div>
  );
};

export default RoutesA;
