import { Margin, Resolution } from "react-to-pdf";

export const PDF_OPTION = ({fileName}) => {
    console.log(fileName)
    return {
                    // default is `save`
                    filename: fileName + ".pdf",
                    method: 'save',
                    // default is Resolution.MEDIUM = 3, which should be enough, higher values
                    // increases the image quality but also the size of the PDF, so be careful
                    // using values higher than 10 when having multiple pages generated, it
                    // might cause the page to crash or hang.
                    resolution: Resolution.MEDIUM,
                    page: {
                    // margin is in MM, default is Margin.NONE = 0
                    margin: Margin.LARGE,
                    // default is 'A4'
                    format: 'A4',
                    // default is 'portrait'
                    orientation: 'portrait',
                    },
                    canvas: {
                    // default is 'image/jpeg' for better size performance
                    mimeType: 'image/png',
                    qualityRatio: 1
                    },
                    // Customize any value passed to the jsPDF instance and html2canvas
                    // function. You probably will not need this and things can break, 
                    // so use with caution.
                    overrides: {
                    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                    pdf: {
                        compress: true
              
                    },
                    // see https://html2canvas.hertzen.com/configuration for more options
                    canvas: {
                        useCORS: true,
                        // scale:1
                    }
        }} }
