import className from 'classnames';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
// import { IndeterminateCheckbox } from '../components/vendor';
import formatLocaleDateString from '../../utils/dateUtils';
import Sidenav from '../../components/SideNav/SideNav';
import { getItemStorage } from '../../utils/storage';
import { switchTypeTicket } from '../../utils/ticketUtils';
import { Link, useNavigate } from 'react-router-dom';
import { listTicketService } from '../../Services/ticketService';
import { IoCodeSlash } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { updateDataCacheTickets } from '../../redux/QRCode';
import { Oval } from 'react-loader-spinner';

export default function ListTicketsPage() {

  let navigate = useNavigate();
  let dispatch = useDispatch();


  const [tableData, setTableData] = useState([]);



  const TicketsInCache = useSelector((state) => state.cacheTickets || null);



  const data = useMemo(() => tableData, [tableData]);

  const handleCopyClick = () => {
    // Seleciona o texto dentro do elemento referenciado
    textRef.current.select();

    try {
      // Tenta copiar o texto para a área de transferência
      document.ex('copy');
      console.log('Texto copiado com sucesso!');
    } catch (err) {
      console.error('Erro ao copiar texto: ', err);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <Link to={"/ticketView/" + row.original.id} title='Veja todas as Informações desse ticket'>
                    <FeatherIcon icon="eye" size="20" />
            </Link>
            {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
          </div>
        ),
      },
      {
        id: 'share',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <Link to={"/ticket/" + row.original.externalId} title='Veja o resultado e compartilhe o link'>
                    <FeatherIcon icon="share-2" size="20" title='Veja o resultado e compartilhe o link'/>
            </Link>
            {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
          </div>
        ),
      },
      {
        id: 'code',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            {/* <Link to={"/ticket/" + row.original.externalId}> */}
                    <IoCodeSlash style={{fontSize: "20px"}} size="20" title='Veja o código que geraria esse ticket'/>
            {/* </Link> */}
            {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
          </div>
        ),
      },
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: 'Portador',
        accessor: 'holderName',
        Cell: ({ value }) => `${value}`,
      },

      // {
      //   Header: 'Teste',
      //   accessor: 3 == 3 ? "holdeName" : "Transport",
      //   Cell: ({ value }) => `${value}`,
      // },
      

      {
        Header: 'Nome do template',
        accessor: 'template.templateName',
        Cell: ({ value }) => `${value}`,
      },

    //   {
    //     Header: 'Tipo de código',
    //     accessor: 'typeCode',
    //     Cell: ({ value }) => switchTypeCode({number: value})

    //   },
      {
        Header: 'Tipo de template',
        accessor: 'template',
        Cell: ({ value }) => switchTypeTicket({number: value.typeTemplate}),
      },
    //   {
    //     Header: 'Background',
    //     accessor: 'backgroundColor',
    //   },
      {
        Header: 'Emitido',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <time dateTime={value}>
            {formatLocaleDateString(value, {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit',
            })}
          </time>
        ),
      },

    ],
    []
  );

  const { getTableBodyProps, headerGroups, page, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  

 const  listTikcets = async () =>{

    const {response, statusCode} = await listTicketService({organizationId: getItemStorage({key: "companyId"})})

    switch (statusCode) {
        case 200:

        //   toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});
          
        //setTableData((prevData) => [...prevData, ...response.response.templates]);


        setTableData(response.tickets)     

        await updateDataCacheTickets({ dispatch: dispatch, data: response.tickets })
        



          break;
        case 409:
        //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
         break;
        default:
        //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }


 }

 useEffect(() => {


  if (TicketsInCache != null){
      setTableData(TicketsInCache);
  }
     
  
  listTikcets();
  
}, [])





useEffect(() => {
  if (TicketsInCache != null){
    setTableData(TicketsInCache);
}

}, [TicketsInCache]);
  
  
  return (
    <>
    <Sidenav/>
    <div className="main-content">


    {TicketsInCache === null ? 
    

    <div
                style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                }}
            >
    
    <Oval
              visible={true}
              height="80"
              width="80"
              color="#2C7BE5"
              secondaryColor="#95AAC9"

              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />

</div>


: 
      <Container fluid style={{padding: "50px"}}>
        <Row className="justify-content-end">
        <Col xs="auto">
          <Button onClick={() => navigate("/ticket/register")}>
            Criar um QRCode
          </Button>
        </Col>
      </Row>
        <Row className="justify-content-center" style={{marginTop: "15px"}} >
          <Col xs={12}>
            <Card>
              <Card.Header>
                <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                  <Form.Control
                    type="search"
                    placeholder="Procurar"
                    onChange={(e) => setGlobalFilter(e.target.value ? e.target.value : undefined)}
                  />
                  <InputGroup.Text>
                    <FeatherIcon icon="search" size="1em" />
                  </InputGroup.Text>
                </InputGroup>
                {/* <Dropdown align="right">
                  <Dropdown.Toggle variant="white" size="sm">
                    Bulk action
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#!">Action</Dropdown.Item>
                    <Dropdown.Item href="#!">Another action</Dropdown.Item>
                    <Dropdown.Item href="#!">Something else here</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </Card.Header>
              <div className='table-responsive'>
              <Table size="xs" className="card-table table-nowrap">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps({
                        
                        role: null,
                      })}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              className: className(column.className, column.canSort && 'is-sortable'),
                              role: null,
                            })
                          )
                
                        }
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps({
                    role: null,
                  })}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps({
                          role: null,
                        })}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.className,
                                role: null,
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
}
    </div>
    </>
  );
}

