import SaveToGooglePayButton from "@google-pay/save-button-react";
import { memo } from "react";

const ButtonsSaveInWallet = ({googleJwt}) => {
    const props = {
        jwt: googleJwt,
        height: "standard",
        size: "matchparent",
        // textsize: "small",
        theme: "dark",
      };
    
  return (
    <div style={{alignContent: "center",  maxWidth:"300px", alignItems: "center", justifyContent:"center", display: "flex",  flexDirection: "column"}}>

        <SaveToGooglePayButton {...props}/>
        <div style={{ marginBottom: "15px" }}/>
        <SaveToGooglePayButton {...props}/>
    </div>
  );
}

export default memo(ButtonsSaveInWallet);
