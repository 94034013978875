import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { Collapse, Container, Dropdown, Form, InputGroup, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NAVBARITEMS from './data';
import Avatar from '../Avatar/Avatar';
import Icon from '../Icon/Icon';
import { getItemStorage, setItemStorage } from '../../utils/storage';
import { getVersionAPI } from '../../Services/statusService';

export default function Sidenav({ ...props }) {
  
  const router = useLocation();
  let navigate = useNavigate();


  const [activeItemId, setActiveItemId] = useState(() => {
    return Object.keys(NAVBARITEMS).filter((itemId) => {
      return NAVBARITEMS[itemId].url === router.pathname;
    })[0];
  });

  const [modalSearchVisible, setModalSearchVisible] = useState(false);
  const [modalNotificationsVisible, setModalNotificationsVisible] = useState(false);

  function isExpanded(itemId) {
    if (activeItemId === itemId) {
      return true;
    }

    return isParent(itemId);
  }

  function isParent(itemId) {
    const item = NAVBARITEMS[itemId];

    if (!item.children) {
      return false;
    }

    if (item.children.includes(activeItemId)) {
      return true;
    }

    let result = false;

    item.children.forEach((childId) => {
      if (isParent(childId)) {
        result = true;
      }
    });

    return result;
  }

  function getItems(ids) {
    return ids.map(function (id, index) {
      const item = NAVBARITEMS[id];

      return (
        <>
        <div key={id}>
          {index > 0 && <hr className="navbar-divider" />}
          {item.title && <h6 className="navbar-heading">{item.title}</h6>}
          {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
        </div>        
        </>
      );
    });
  }

  function getSubitems(ids, parentId, arr) {
    return ids.map(function (id) {
      const item = NAVBARITEMS[arr.splice(arr.indexOf(id), 1)];
      return (
        <Nav.Item key={id}>
          {
                    <Nav.Link active={router.pathname === item.url} onClick={() => handleClick(id, parentId)}>
                    <Link to={item.url}>
                        {item.icon && item.icon}
                        <span style={{marginLeft: "15px"}}>{item.title}</span>
                        </Link>
                    </Nav.Link>
              
            }
        </Nav.Item>
        
      );
    });
  }

  function handleClick(itemId, parentId, setVisible) {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);

    if (setVisible) {
      setVisible(false);
    }
  }


  function handleLogout() {
    localStorage.clear();
    navigate("/login");
  }

  const toggler = <Navbar.Toggle />;

  const brand = (
    <Link href="/" passHref>
      <Navbar.Brand>
        <img className="navbar-brand-img" src="/img/logo.svg" alt="..." />
      </Navbar.Brand>
    </Link>
  );

  const user = (
    <Dropdown align="end" className="d-md-none">
      <Dropdown.Toggle as={Avatar} size="sm" role="button">
        <Avatar.Image className="rounded-circle" src={getItemStorage({key: "organizationLogo"})} alt="..." />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link href="/profile-posts" passHref>
          <Dropdown.Item>Profile</Dropdown.Item>
        </Link>
        <Link href="/account-general" passHref>
          <Dropdown.Item>Settings</Dropdown.Item>
        </Link>
        <Dropdown.Divider />
        <Link href="/sign-in" passHref>
          <Dropdown.Item>Logout</Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );

//   const form = (
//     <form className="mt-4 mb-3 d-md-none">
//       <InputGroup className="input-group-merge input-group-reverse input-group-rounded">
//         <Form.Control type="search" placeholder="Search" aria-label="Search" />
//         <InputGroup.Text>
//           <FeatherIcon icon="search" size="15" />
//         </InputGroup.Text>
//       </InputGroup>
//     </form>
//   );
    const testiniho = async () => {
      const data = await getVersionAPI();

      setItemStorage({key: "VERSION", value: data})
    }

    useEffect(() => {
        
      testiniho();
      
    }, [])


    



  const footer = (
    <>
    <div style={{"alignItems": "center", "display": "flex", justifyContent: "center"}}>
          <span style={{color: "#95AAC9", fontWeight:"600", marginBottom: "5px", letterSpacing: "0.08em"}}>{getItemStorage({key: "VERSION"})}</span>
    </div>  
    <div className="navbar-user d-none d-md-flex">
      {/* <a className="navbar-user-link" role="button" onClick={() => setModalNotificationsVisible(true)}>
      <Link to="/switch-organization"> 
        <Icon>
          <FeatherIcon icon="grid" size="17" />
        </Icon>
        </Link>
      </a> */}
      <Icon>
      </Icon>
      <Dropdown drop="up">
        <Dropdown.Toggle as={Avatar} size="sm" role="button">
          <Avatar.Image className="rounded-circle" src={getItemStorage({key: "organizationLogo"})} alt="..." />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Link to="/switch-organization"> 
                    <Dropdown.Item>
                            Trocar organização 
                            
                    </Dropdown.Item>
          </Link> */}
          {/* <Dropdown.Divider /> */}
          <Link to="/login" onClick={handleLogout}> 
                <Dropdown.Item >
                        Sair
                </Dropdown.Item>
            </Link>
        </Dropdown.Menu>
      </Dropdown>
      <a className="navbar-user-link" role="button" onClick={() => setModalSearchVisible(true)}>
         <Link to="/login" onClick={handleLogout}> 

        <Icon>
          <FeatherIcon icon="log-out" size="17" />
        </Icon>
        </Link>
      </a>
    </div>
    </>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {/* {form} */}
      {getItems(Object.keys(NAVBARITEMS))}
      <div className="mt-auto mb-md-4" />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <>
      <Navbar expand="md" className="navbar-vertical fixed-start" collapseOnSelect={true} {...props}>
        <Container fluid>
          {toggler}
          {brand}
          {user}
          {collapse}
        </Container>
      </Navbar>
      {/* <ModalSearch visible={modalSearchVisible} onDismiss={() => setModalSearchVisible(false)} />
      <ModalNotifications visible={modalNotificationsVisible} onDismiss={() => setModalNotificationsVisible(false)} /> */}
    </>
  );
}
