export const setItemStorage = ({key, value}) => {
    localStorage.setItem(key, value);
}


export const getItemStorage = ({key}) => {
    const  data = localStorage.getItem(key);
    return data

}



export const removeItemStorage = ({key}) => {
   localStorage.removeItem(key);
}