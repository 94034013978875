import { Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import TransportTicket from '../../components/qrTemplates/transportTicket';
import { formContext } from '../../context/context';
import EventTicket from '../../components/qrTemplates/eventTicket';
import RegisterTemplateForm from '../../components/RegisterTemplateForm/RegisterTemplateForm';
import { GOOGLE_JWT } from '../../Resources/text';
import LoyaltyCard from '../../components/qrTemplates/customerCard';
import { getTemplateByIdService } from '../../Services/templateService';
import { getItemStorage } from '../../utils/storage';
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from 'react-router-dom';


export default function RegisterTemplatePage({isEditable, ...ohterParams}) {
  
  const [issuerName, setIssuerName] = useState("");
  const [companyZip, setCompanyZip] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [typeTicket, setTypeTicket] = useState(0);
  const [typeCode, setTypeCode] = useState(null);
  const [ticketCompanyLogo, setTicketCompanyLogo] = useState(null);
  const [ticketCompanyBanner, setTicketCompanyBanner] = useState(null);
  const [userInOrganization, setUserInOrganization] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [promoName, setPromoName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const TemplateTicket = () =>{

    const originDate = new Date();

    switch (typeTicket) {
      case 1:
        return <TransportTicket typeCode={typeCode} issuerName={issuerName}
                                codeValue={"TEMPLATE"}
                                metadata={[
                                  {"title": "Tempo estimado de viagem", "value": '5h 00m'},
                                  {"title": "Parada", "value": 'Volta Redonda (Graal)'},
                                  {"title": "Ônibus", "value": "Paradiso G7 (LEITO)"}
                                ]}
                                companyLogo={ticketCompanyLogo}
                                origin={"Rio de Janeiro (Novo Rio)"}
                                destination={"São Paulo (Terminal Tiete)"}
                                originDate={originDate}
                                destinationDate={new Date(originDate.getTime() + 5 * 60 * 60 * 1000)}
                                ticketNumber={"55F60X"}
                                passenger={"Caio da Silva Lucas"}
                                backgroundColor={backgroundColor}
                                googleJwt={GOOGLE_JWT}
                                platform={"70"}
                                seat={"12"}
                                originCode={"RJ"}
                                destinationCode={"SP"}
                                />

      case 2:
        //EVENTO

        return <EventTicket issuerName={issuerName} eventName={eventName} eventDate={eventStartDate === null ? new Date() : eventStartDate}
                            metadata={[
                              {"title": "Sala", "value": "Sala 1"},
                              {"title": "Poltrona", "value": "JK23"},
                              {"title": "Duração", "value": "2h 30m"},
                              {"title": "Classificação indicativa", "value": "14 anos"}]}
                            identifier={"X5A8X"}
                            userName={"Matheus Gomes Silva"}
                            companyLogo={ticketCompanyLogo}
                            backgroundColor={backgroundColor}
                            typeCode={typeCode}
                            googleJwt={GOOGLE_JWT}
                            codeValue={"TEMPLATE"}
                            />


      case 3:
        // Cartão fidelidade
        return <LoyaltyCard issuerName={issuerName} 
                             backgroundColor={backgroundColor}
                              promoName={promoName}
                              metadata=
                              {[
                                {"title": "Instruções", "value": "Apresentar o bilhete na hora do pagamento"},
                                {"title": "Validade", "value": "Qualquer estabelecimento da rede"},
                               ]}
                              identifier={"X5A8X"}
                              userName={"Naiara Marques Costa"}
                              companyLogo={ticketCompanyLogo}
                              codeValue={"TEMPLATE"}
                              typeCode={typeCode}
                              googleJwt={GOOGLE_JWT}
                              nameLabel={nameLabel}
                              />

      default:
        
        break;
    }
  }


  const testinho = async () =>{
    const response = await getTemplateByIdService({organizationId: getItemStorage({key: "companyId"}), templateId: ohterParams.templateId})

    switch (response.statusCode) {
        case 200:

        //   toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});
         
         setTypeTicket(response.response.template.typeTemplate);
         setIssuerName(response.response.template.issuerName);
         setTemplateName(response.response.template.templateName);;
         setBackgroundColor(response.response.template.backgroundColor)
         setTicketCompanyLogo(response.response.template.logoUrl)
         setTicketCompanyBanner(response.response.template.bannerUrl);
         setTypeCode(response.response.template.typeCode);
         setPromoName(response.response.template.promoName);
         setEventName(response.response.template.eventName);
         setNameLabel(response.response.template.nameLabel);
         console.log(response.response.template)
         if (response.response.template.timeInterval){
          // var testinho = response.response.template.endDate.replace(/^.*, (\d+ \w+ \d+ \d+:\d+:\d+) GMT$/, '$1');
          setEventStartDate(new Date(response.response.template.timeInterval.startDate));
          setEventEndDate(new Date(response.response.template.timeInterval.endDate));
         }


        //  setEventEndDate(response.response.template.endDate);


        //  setEventStartDate(response.response.template.startDate);
        //setTableData((prevData) => [...prevData, ...response.response.templates]);



          break;
        case 409:
        //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
         break;
        default:
        //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }
  }
  

  useEffect(() => {
    if (isEditable == false) {
      testinho();
    }
    
    
    }, [])
  return (
    <>
   
 
    <formContext.Provider value={{ issuerName, setIssuerName, 
                                  typeTicket, setTypeTicket, 
                                  companyZip, setCompanyZip,
                                  companyWebsite, setCompanyWebsite,
                                  companyDescription, setCompanyDescription,
                                  companyEmail, setCompanyEmail,
                                  typeCode, setTypeCode,
                                  ticketCompanyLogo, setTicketCompanyLogo,
                                  ticketCompanyBanner, setTicketCompanyBanner,
                                  backgroundColor, setBackgroundColor,
                                  eventName, setEventName,
                                  eventStartDate, setEventStartDate,
                                  eventEndDate, setEventEndDate,
                                  promoName, setPromoName,
                                  templateName, setTemplateName,
                                  nameLabel, setNameLabel
                                  }}>

                                    <Link to={"/templates"}>
                                        <IoIosArrowRoundBack style={{fontSize: "50px", marginLeft: "10px"}}/>
                                    </Link>

    <div className="d-flex align-items-center min-vh-100 bg-auth border-primary">
    
     <Container>
     <Row className={typeTicket == 0 ? "justify-content-center" : "align-items-center"}>

        {
            typeTicket != 0 ? (
                <Col xs={12} md={6} className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 custom-style-for-type-0">
                    <div>
                        {TemplateTicket()}
                    </div>
                </Col>
            ) : null
        }

          <Col xs={12} md={5} xl={4} className={typeTicket == 0 ? "my-5": "order-md-1 my-5"}>
              <RegisterTemplateForm  isEditable={isEditable}/>
          </Col>  
      
      </Row>


    </Container>





    </div>
    </formContext.Provider>
    
    </>
  );
}
