import { getItemStorage } from '../utils/storage';
import api from './api';
import { useRefreshToken } from './refreshToken';

export const createOrganizationService = async ({ companyLogo, name, email, zipCode, site, description }) => {
    try {
      const form = new FormData();
      form.append("logo", companyLogo);
      form.append("name", name);
      form.append("zipCode", zipCode);
      form.append("email", email);
      form.append("site", site);
      form.append("description", description);
  
      const response = await api.post("/organization", form, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 201) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await createOrganizationService({ companyLogo: companyLogo, name: name, email: email, zipCode: zipCode, site: site, description: description });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };
  


  export const listAllOrganizationsService = async () => {
    try {

        const response = await api.get("/organizations", {
          headers: {
              Authorization:`Bearer ${getItemStorage({
                  key: "@QRConnect:Prod:At",
                })}`
          }});
        if (response.status === 200){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
      console.log("erro")
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await listAllOrganizationsService();
      }
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}



// const options = {
//   method: 'POST',
//   url: 'http://localhost:4050/organization',
//   headers: {'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001'},
//   data: '[form]'
// };

// axios.request(options).then(function (response) {
//   console.log(response.data);
// }).catch(function (error) {
//   console.error(error);
// });