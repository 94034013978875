import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { validEmail, validPassword } from '../../utils/stringUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authUserService, createUserService } from '../../Services/userService';
import { setItemStorage } from '../../utils/storage';
import { jwtDecode } from 'jwt-decode';


export default function SignUpForm() {

  const [name, setName] = useState("");
  const [nameIsValid, setNameIsValid] = useState("");
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  let navigate = useNavigate();


  const createUser = async () => {
      if (formIsValid){

        const id = toast.loading('Criando o usuário... ⏳', {
          position: "top-right",
          theme: "light"
          });
          
          const response = await createUserService({email: email, password: password, name:name})

          switch (response.statusCode) {
            case 201:
              
              const responseAuth = await authUserService({email: email, password: password});


              toast.update(id, {render: "Usuário criado", type: "success", isLoading: false, autoClose: 4000});
              
              setItemStorage({
                key: "@QRConnect:Prod:At",
                value: responseAuth.response.accessToken,
              });
              
              setItemStorage({
                key: "@QRConnect:Prod:Rt",
                value: responseAuth.response.refreshToken,
              });
              
              const decoded = jwtDecode(responseAuth.response.accessToken);

              setItemStorage({ key: "userName", value: decoded.name });
              setItemStorage({ key: "organizationLogo", value: decoded.organizationLogo });
              setItemStorage({key: "companyId",value: decoded.organizationId });
              navigate("/organization/register");


              break;
            case 409:
             toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
             break;
            default:
              toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
              break;
          }


      }
  }
  
  useEffect(() => {
    if (name.length > 3){
      setNameIsValid(true)
    }else{
      setNameIsValid(false);
    }
  }, [name])

  useEffect(() => {
    if (email === ""){
      setEmailIsValid(null)
    }else{
      let isValidEmail = validEmail({email: email});
      setEmailIsValid(isValidEmail)
    }

  }, [email])
  
  useEffect(() => {

    if (password === ""){
      setPasswordIsValid(null)
    }else{
      let isValidPassword = validPassword({password: password});
      setPasswordIsValid(isValidPassword)
    }
  }, [password])
  
  
  useEffect(() => {
    if (passwordIsValid && emailIsValid && nameIsValid){
      setFormIsValid(true)
    }else{
      setFormIsValid(false);
    }
  }, [passwordIsValid, emailIsValid, nameIsValid])



  return (
    <>
      <h1 className="display-4 text-center mb-3">Cadastro</h1>
      <p className="text-muted text-center mb-5">Crie sua conta e acesse o dashboard.</p>
      <form>
      <div className="form-group">
          <Form.Label>Nome</Form.Label>
          <Form.Control type="text" placeholder="Marcos da Silva" onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className="form-group">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" placeholder="nome@email.com.br" onChange={(e) => setEmail(e.target.value)} isValid={emailIsValid} isInvalid={emailIsValid == null? false : !emailIsValid}/>
          <Form.Control.Feedback type="invalid">E-mail inválido</Form.Control.Feedback>
        </div>
        <div className="form-group">
          <Row>
            <Col>
              <Form.Label>Senha</Form.Label>
            </Col>
          </Row>
          <InputGroup className="input-group-merge">
            
            <Form.Control type={hidePassword ? "password" : "text"} placeholder="Digite sua senha" onChange={(e) => setPassword(e.target.value)} isInvalid={passwordIsValid == null? false: !passwordIsValid}/>
            <InputGroup.Text>
              <FeatherIcon icon="eye" size="1em" onClick={()=> setHidePassword(!hidePassword)}/>
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Senha deve ter 6+ caracteres alfanuméricos e pelo menos 1 caractere especial: !@#$%*&.</Form.Control.Feedback>

          </InputGroup>
        </div>
        <Button size="lg" className="w-100 mb-3" onClick={createUser} disabled={!formIsValid}>
          Cadastrar
        </Button>
        <Link to="/">
        <p className="text-center">
          <small className="text-muted text-center">
            Já possui uma conta?{' '}
            
              <a>Login</a>
            
            .
          </small>
        </p>
        </Link>
      </form>
      
    </>
  );
}
