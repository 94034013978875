import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

export default function CustomerCardTemplateForm({promoName, setPromoName, promoNameIsValid, isEditable, nameLabel, setNameLabel, nameLabelIsValid}) {


return (
            <>
                <div className="form-group">
                    <Form.Label>
                                Nome do promoção{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome da promoção"
                                />
                    </Form.Label>
                    <Form.Control type="text" placeholder="Nome da promoção" onChange={(e) => setPromoName(e.target.value)} value={promoName} 
                    isInvalid={promoNameIsValid == null ? false : !promoNameIsValid} disabled={isEditable== false}/>
                    <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
                </div>
                <div className="form-group">
                    <Form.Label>
                                Label do usuário{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Como voce irá chamar o seu usuário (ex: Cliente, Colaborador, Nome)"
                                />
                    </Form.Label>
                    <Form.Control type="text" placeholder="Label do usuário" onChange={(e) => setNameLabel(e.target.value)} value={nameLabel} 
                    isInvalid={nameLabelIsValid == null ? false : !nameLabelIsValid} disabled={isEditable== false}/>
                    <Form.Control.Feedback type="invalid">Label inválida</Form.Control.Feedback>
                </div>  
            </>
)
}