import { getItemStorage } from '../utils/storage';
import api from './api';
import { useRefreshToken } from './refreshToken';

export const createUserService = async ({name, email, password}) => {
    try {

        const response = await api.post("/user", {
            "name": name,
            "email": email,
            "password": password
            });
        if (response.status === 201){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}


export const authUserService = async ({email, password}) => {
    try {

        const response = await api.post("/user/auth", {
            "email": email,
            "password": password
            });
        if (response.status === 200){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}


export const requestLinkRecoveryPasswordService = async ({email}) => {
    try {

        const response = await api.post("/user/recoveryPassword", {
            "email": email,
            });
        if (response.status === 200){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}



export const checkURLSerializedService = async ({ urlSerialized }) => {
    try {
        const response = await api.get("/checkURLSerialized", {
            params: {
                urlSerialized: urlSerialized,
            },
        });

        if (response.status === 204) {
            return { response: response.data.data, statusCode: response.status };
        }
    } catch (error) {
        return {
            response: error.response.data,
            statusCode: error.response.status,
        };
    }
};



export const changePasswordService = async ({urlSerialized, password}) => {
    try {

        const response = await api.post("/user/changePassword", {
            "urlSerialized": urlSerialized,
            "password": password
            });
        if (response.status === 204){
            return {response: response.data.data, statusCode: response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {response: error.response.data, statusCode: error.response.status}

    }
}



export const checkUserInOrganizationService = async () => {
    try {
        const response = await api.get("/user/inOrganization", {
            headers: {
                Authorization:`Bearer ${getItemStorage({
                    key: "@QRConnect:Prod:At",
                  })}`
            }}
          );

        if (response.status === 200) {
            return { response: response.data.data, statusCode: response.status };
        }
    } catch (error) {
        if (error.response.status == 401) {
            await useRefreshToken();
      
            return await checkUserInOrganizationService();
          }

        return {
            response: error.response.data,
            statusCode: error.response.status,
        };
    }
};


export const checkAuth = async () => {
    try {

        const response = await api.get("/auth", {
            headers: {
                Authorization:`Bearer ${getItemStorage({
                    key: "@QRConnect:Prod:At",
                  })}`
            }});
        if (response.status === 204){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        if (error.response.status == 401) {
            await useRefreshToken();
      
            return await checkAuth();
          }

        return {"response": error.response.data, "statusCode": error.response.status}

    }
}

