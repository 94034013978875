export const switchTypeTicket = ({number}) => {
    switch (number) {
        case 1:
            return "Transporte"
            break;
        case 2:
            return "Evento"
            break;
        case 3:
                return "Fidelidade"
                break;
        default:
            return "undefined"
            break;
    }
}


export const switchTypeCode = ({number}) => {
    switch (number) {
        case 1:
            return "QR CODE"
            break;
        case 2:
            return "Código de barra"
            break;
        default:
            return "undefined"
            break;
    }
}
