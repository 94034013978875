import { useParams } from "react-router-dom";
import RegisterTemplatePage from "./RegisterTemplatePage";

export default function ViewTemplatePage() {

    const {templateId} = useParams();

    return (
        <>
            <RegisterTemplatePage isEditable={false} templateId={templateId}/>
        </>
    )
}