import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
//import { checkAuth } from '../Services/userService';
//import { useRefreshToken } from '../Services/refreshToken';
import { getItemStorage } from '../utils/storage';

export const UnprotectedRoute  = ({children}) => {


    let navigate = useNavigate();

    const logIn =  () =>{
        //localStorage.clear();
        navigate("/");
    }


    const isAuth = async () =>{
        if (getItemStorage({key: "@QRConnect:Prod:At"}) == null){



        //    const response = await checkAuth();

        //    if (response.statusCode != 204){
        //         await useRefreshToken();
        //         logout();
        //     }        

        }else{
            logIn();
        }

    }



    useEffect(() => {
        isAuth();
    }, [])

      return (
        <>{getItemStorage({key: "@QRConnect:Prod:At"}) == null ?  children : <Navigate to={"/"}/> }</>
      )
      
    };
