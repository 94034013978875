import React, {  } from 'react';
import QRCode from 'qrcode.react';
import { TicketCodeZone, TicketContainer, TicketDivider, TicketHeader } from './styles';
import { getContrastColor } from '../../utils/color';
import Barcode from 'react-barcode';
import ButtonsSaveInWallet from '../ButtonsSaveInWallet';
import { IoMdDownload } from "react-icons/io";
import generatePDF, {  } from 'react-to-pdf';
import { PDF_OPTION } from '../../Resources/pdf';
import { ToastContainer, toast } from 'react-toastify';

const LoyaltyCard = ({issuerName, companyLogo, promoName, metadata, 
                       identifier, userName, backgroundColor, typeCode, codeValue, googleJwt, nameLabel, isExtern=false}) => {

  const sectionStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    paddingTop: '16px',
  };


  const cinemaNameStyle = {
    fontSize: '17px',
    color: 'black',
    marginBottom: '16px',
    fontWeight: 'bold',
  };

  const movieInfoStyle = {
    marginBottom: '8px',
  };


  const locationStyle = {
    fontSize: '14px',
    color: '#555',
  };

  const passengerTitleStyle = {
    fontWeight: '500',
    // marginBottom: '4px',
    color: getContrastColor({background: backgroundColor})
  };

     
  const passengerInfoStyle = {
    fontSize: '18px',
    color: getContrastColor({background: backgroundColor}),
  };
  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '16px',
  };

  const passengerStyle = {

    paddingTop: '16px',
  };

  const getTargetElement = () => document.getElementById('cardContainer');


  const filename = issuerName + "_" + promoName.replace(" ", "_").replace(/ /g, "_") +  "-" + userName.replace(/ /g, "_") + '.pdf'
  

  const options = PDF_OPTION({fileName: filename})
  console.log(companyLogo)


  const donwloadPDF = () => {
    
    const id = toast.loading('Gerando PDF... ⏳', {
      position: "top-right",
      });

      generatePDF(getTargetElement, options)

      toast.update(id, {render: "PDF gerado com sucesso", type: "success", isLoading: false, autoClose: 2000});
  }

  return (
    <div >

    <TicketContainer backgroundColor={backgroundColor} id="cardContainer">
      <TicketHeader>
        <img
              src={companyLogo === null ? "https://placehold.co/512x512": typeof(companyLogo) === "string" ? companyLogo : URL.createObjectURL(companyLogo)}
              alt={issuerName + " logo"}
              style={{ height: '50px' }}
        />
        <h2 style={{ margin:0, fontSize: '18px', fontWeight: '500', marginLeft:"15px", color: getContrastColor({background: backgroundColor}) }}>{issuerName}</h2>
        {isExtern &&  <div id="buttonDownload" style={{ marginLeft: "auto", cursor: "pointer"}} onClick={donwloadPDF}>
                    <IoMdDownload style={{ fontSize: "30px", marginRight: "10px", color: getContrastColor({ background: backgroundColor })}} />
                  </div>}
      </TicketHeader>

      <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

      <div style={sectionStyle}>
        <div>
          <p style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}) }}>{promoName}</p>
          <div style={movieInfoStyle}>
            {metadata.map((item, index) => (
                <React.Fragment key={index}>
                  {item.title !== "" ? <>
                  <span style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>{`${item.title}: ${item.value}`}</span>
                  <br />
                  </>: <></>}
                  
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
      
    


      {/* <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/> */}

      <div style={passengerStyle}>
            <h3 style={passengerTitleStyle}>{nameLabel}</h3>
            <div>
              <p style={passengerInfoStyle}>{userName}</p>
              {/* <p style={{ fontSize: '12px', color: '#aaa' }}>20 kg., In-flight meal</p> */}
            </div>
          </div>




      <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

      <TicketCodeZone >
            
            {typeCode === 1 || typeCode === null ? 
                                  <div style={{ background: 'white', padding: '18px', borderRadius: "20px", width: "max-content" }}>
                                  <QRCode value={codeValue} size={200} /> </div>:  
                                  <Barcode value={codeValue} width={2.5} displayValue={false}/>
            }

          </TicketCodeZone>

          <div id='buttonZone' data-html2canvas-ignore="true">
                <div style={buttonContainerStyle}>
                                    <ButtonsSaveInWallet googleJwt={googleJwt}/>
                </div>
          </div>
     
    </TicketContainer>
    

    </div>
  );
};

export default LoyaltyCard;
