import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isValidCEP, isValidWebsite, validEmail } from '../../utils/stringUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formContext } from '../../context/context';
// import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { createOrganizationService } from '../../Services/organizationService';
import { setItemStorage } from '../../utils/storage';
import Resizer from 'react-image-file-resizer';
import { useRefreshToken } from '../../Services/refreshToken';


export default function RegisterOrganizationForm() {

  const [companyNameIsValid, setComapanyNameIsValid] = useState(null);
  const [companyEmailIsValid, setCompanyEmailIsValid] = useState(null);
  const [password, setPassword] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [companyWebsiteIsValid, setCompanyWebsiteIsValid] = useState(null);
  const [companyZipIsValid, setCompanyZipIsValid] = useState(null);

  let navigate = useNavigate();

  const { companyName, setCompanyName, 
    // typeTicket, setTypeTicket, 
    companyZip, setCompanyZip,
    companyWebsite, setCompanyWebsite,
    companyDescription, setCompanyDescription,
    companyEmail, setCompanyEmail,
    // typeCode, setTypeCode,
    avatar, setAvatar
    } = useContext(formContext);



  const onDrop = useCallback((acceptedFiles) => {
      // Atualiza o estado do avatar
      // console.log(acceptedFiles[0])
      // setAvatar(acceptedFiles[0]);
      // console.log(avatar)

      const file = acceptedFiles[0];

    // Verifica se o arquivo é uma imagem
    if (file && file.type.startsWith('image/')) {
      // Cria uma instância de FileReader para ler as dimensões da imagem
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (file && file.type.startsWith('image/')) {
            Resizer.imageFileResizer(
              file, 
              512, 
              512, 
              'JPEG', 
              100, 
              0, 
              (uri) => {
                // uri é o arquivo redimensionado
                setAvatar(uri);
              },
              'blob' // ou 'base64', dependendo do formato que você deseja
            );
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Por favor, selecione um arquivo de imagem válido.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      // alert('Por favor, selecione um arquivo de imagem válido.');
    }
    }, []);
  
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'image/*',
      onDrop,
      multiple: false
    });
  

  // const createUser = async () => {
  //     if (formIsValid){

  //       const id = toast.loading('Criando o usuário... ⏳', {
  //         position: "top-right",
  //         theme: "light"
  //         });
          
  //         const response = await createUserService({email: email, password: password, name:name})
  //         console.log(response)
  //         switch (response.statusCode) {
  //           case 201:

  //             toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});

  //             console.log(response.response.data.organization);
  //             setItemStorage({ key: "organizationLogo", value: response.response.data.organization });

  //             setTimeout(function() {
  //                 navigate("/template/register");
  //             }, 1000);
  //             break;
  //           case 409:
  //            toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
  //            break;
  //           default:
  //             toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
  //             break;
  //         }


  //     }
  // }
  
  useEffect(() => {
    if (companyName === ""){
      setComapanyNameIsValid(null);
    }else{
      if (companyName.length > 3){
        setComapanyNameIsValid(true)
      }else{
        setComapanyNameIsValid(false);
      }
    }
   
  }, [companyName])


  useEffect(() => {
    if (companyEmail === ""){
      setCompanyEmailIsValid(null)
    }else{
      let isValidEmail = validEmail({email: companyEmail});
      console.log(isValidEmail)
      setCompanyEmailIsValid(isValidEmail)
    }

  }, [companyEmail])
  



  useEffect(() => {
    if (companyWebsite === ""){
      setCompanyEmailIsValid(null);

    }else{
    if (isValidWebsite(companyWebsite)) {
          setCompanyWebsiteIsValid(true);
        } else {
          setCompanyWebsiteIsValid(false);
        }

  }
  }, [companyWebsite]);




  const dropzoneStyle = {
    border: avatar ? "none" :'2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    height: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  };

  const formatZip = (value) => {
    // Remove qualquer não-dígito e limita a 8 caracteres
    const cleanedValue = value.replace(/\D/g, '').slice(0, 8);
    
    // Adiciona o traço antes dos últimos 3 dígitos
    const formattedValue = cleanedValue.replace(/(\d{5})(\d{3})/, '$1-$2');

    return formattedValue;
  };


  const handleZipChange = (e) => {
    let inputValue = e.target.value;

    if (inputValue.length <= 9){
      inputValue = inputValue.replace(/\D/g, '');

      const formattedValue = formatZip(inputValue);

      setCompanyZip(formattedValue);
      setCompanyZipIsValid(isValidCEP({cep: formattedValue}));
    }

  };

  useEffect(() => {

  if (companyEmailIsValid && companyZipIsValid && companyNameIsValid && companyWebsiteIsValid && companyDescription !== "" && avatar !== null){
      setFormIsValid(true)
    }else{
      setFormIsValid(false);
    }
  }, [companyEmailIsValid, companyZipIsValid, companyNameIsValid, companyWebsiteIsValid, companyDescription, avatar, avatar])





  const createOrganization = async () => {

    const id = toast.loading('Criando organização... ⏳', {
      position: "top-right",
      theme: "light"
      });

    const response = await createOrganizationService({ companyLogo: avatar, name: companyName, email: companyEmail, zipCode: companyZip, site: companyWebsite, 
      description: companyDescription })

      switch (response.statusCode) {
        case 201:
          console.log(response.response)


          toast.update(id, {render: "Organização criada com sucesso", type: "success", isLoading: false, autoClose:4000});

          setItemStorage({ key: "organizationLogo", value: response.response.organization.logoId });
          setItemStorage({key: "companyId",value: response.response.organization.id });


          await useRefreshToken();


          setTimeout(function() {
            navigate("/template/register");
        }, 1000);
          break;
        case 409:
          toast.update(id, {render: response.response.message, type: "warning", isLoading: false, autoClose: 2000});
          setTimeout(function() {
            navigate("/");
        }, 2000);
          break;


        default:
          toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }
  

  }

  return (
    <>
      <h1 className="display-4 text-center mb-3">Organização</h1>
      <p className="text-muted text-center mb-5">Adicione informações sobre sua organização</p>
      <form>

      <div className="form-group">
      <Form.Label>Logo da empresa (512x512)</Form.Label>
      <div style={{alignItems: "center", textAlign: "center"}}>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} accept="image/*"/>
        <p style={{margin: "unset"}}>Clique aqui e faça o upload</p>

        {/* {isDragActive ? (
          <p>Arraste e solte a imagem aqui</p>
        ) : (
          <p>Clique ou arraste uma imagem aqui para fazer upload</p>
        )} */}
      </div>

      {/* Pré-visualização da imagem */}
      {avatar && (
        <div>
          <img
            src={URL.createObjectURL(avatar)}
            alt="Avatar Preview"
            style={{ maxWidth: '200px', maxHeight: '200px' }}
          />
        </div>
      )}
        </div>
      </div>
      <div className="form-group">
          <Form.Label>Nome da Empresa</Form.Label>
          <Form.Control type="text" placeholder="Cinema CineBox" onChange={(e) => setCompanyName(e.target.value)} value={companyName} isInvalid={companyNameIsValid == null ? false : !companyNameIsValid}/>
        </div>
        <div className="form-group">
          <Form.Label>CEP</Form.Label>
          <Form.Control type="text" placeholder="25645111" onChange={handleZipChange} value={companyZip} isInvalid={companyZipIsValid == null ? false : !companyZipIsValid} />
          <Form.Control.Feedback type="invalid">CEP inválido</Form.Control.Feedback>
        </div>
        <div className="form-group">
          <Form.Label>Website</Form.Label>
          <Form.Control type="text" placeholder="www.meusite.com" onChange={(e) => setCompanyWebsite(e.target.value)} value={companyWebsite} isInvalid={companyWebsiteIsValid == null ? false: !companyWebsiteIsValid}/>
          <Form.Control.Feedback type="invalid">Site inválido ou não existe</Form.Control.Feedback>
        </div>
        <div className="form-group">
          <Form.Label>Descrição</Form.Label>
          <Form.Control type="text" placeholder="Descrição" onChange={(e) => setCompanyDescription(e.target.value)} value={companyDescription}/>
          <Form.Control.Feedback type="invalid">E-mail inválido</Form.Control.Feedback>
        </div>
        <div className="form-group">
          <Form.Label>E-mail de contato</Form.Label>
          <Form.Control type="email" placeholder="empresa@email.com" onChange={(e) => setCompanyEmail(e.target.value)} isInvalid={companyEmailIsValid == null? false : !companyEmailIsValid} value={companyEmail}/>
          <Form.Control.Feedback type="invalid">E-mail inválido</Form.Control.Feedback>
        </div>
        {/* <div className="form-group">
        <Form.Label>Template do ticket</Form.Label>
        <ReactSelect
              options={[
                { value: 1, label: 'Passagem de transporte' },
                { value: 2, label: 'Ingresso cinema' },
                { value: 3, label: 'Ingresso balada' },
              ]}
              placeholder={null}
              onChange={(e) => onSetValueTicket(e)}
              value={valueTicket}
              isDisabled={!avatar}
            />
        </div>
        <div className="form-group">
        <Form.Label>Tipo de código</Form.Label>
        <ReactSelect
              options={[
                { value: "qr", label: 'QR code' },
                { value: "bar", label: 'Código de barra' }
              ]}
              placeholder={null}
              onChange={(e) => onSetValueTypeTicket(e)}
              value={valueTypeCode}
              isDisabled={!avatar}
            />
        </div> */}




        <Button size="lg" className="w-100 mb-3" onClick={createOrganization} disabled={!formIsValid}>
          Registrar
        </Button>
      </form>
      
    </>
  );
}
