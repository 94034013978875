import api from "./api";

export const getVersionAPI = async () => {

    try {

        const response = await api.get("/status");
        if (response.status === 200){
            return response.data.version
        }else{
            return "0.0.-1"
        }
        
    } catch (error) {
        // console.log(error)
        return false

    }

}