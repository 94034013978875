import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactSelect from 'react-select';
import { formContext } from '../../context/context';
// import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { CirclePicker } from 'react-color';
import { createTemplateService } from '../../Services/templateService';
import { getItemStorage } from '../../utils/storage';
import { FaInfoCircle } from 'react-icons/fa';
import TransportTikcetTemplateForm from './transportTicketTemplateForm';
import EventTicketTemplateForm from './EventTicketTemplateForm';
import CustomerCardTemplateForm from './CustomerCardTemplateForm';
import Resizer from 'react-image-file-resizer';

const bannerInfo = {
  1: {
    title: "Banner da sua empresa: uma imagem retangular que será exibida na seção de mais informações do ticket.",
    label: "Banner da empresa (1022x304)"
  },
  2: {
    title: "Banner da empresa ou do evento: recomendamos um banner do evento. Uma imagem retangular que ficará na seção de mais informações do ticket.",
    label: "Banner do evento (1022x304)"
  },
  3: {
    title: "Banner da sua empresa ou promoção: uma imagem retangular que será exibida na seção de mais informações do ticket.",
    label: "Banner da sua empresa ou promoção (1022x304)"
  }
};

export default function RegisterTemplateForm({isEditable}) {
  
  const [issuerNameIsValid, setIssuerNameIsValid] = useState(null);
  // const [companyEmailIsValid, setCompanyEmailIsValid] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [companyWebsiteIsValid, setCompanyWebsiteIsValid] = useState(null);
  const [companyZipIsValid, setCompanyZipIsValid] = useState(null);
  const [valueTicket, setValueTIcket] = useState({})
  const [valueTypeCode, setValueTypeCode] = useState({})
  const [templateNameIsValid, setTemplateNameIsValid] = useState(null);
  const [eventNameIsValid, setEventNameIsValid] = useState(null);
  const [promoNameIsValid, setPromoNameIsValid] = useState(null);
  const [nameLabelIsValid, setNameLabelIsValid] = useState(null);


  
  let navigate = useNavigate();

  const { issuerName , setIssuerName, 
    typeTicket, setTypeTicket, 
    templateName, setTemplateName,
    
    
    
    typeCode, setTypeCode,
    ticketCompanyLogo, setTicketCompanyLogo,
    ticketCompanyBanner, setTicketCompanyBanner,
    backgroundColor, setBackgroundColor,
    eventName, setEventName,
    eventStartDate, setEventStartDate,
    eventEndDate, setEventEndDate,
    promoName, setPromoName,
    nameLabel, setNameLabel
    } = useContext(formContext);




  useEffect(() => {
    if (issuerName === ""){
      setIssuerNameIsValid(null);
    }else{
      if (issuerName.length > 3){
        setIssuerNameIsValid(true)
      }else{
        setIssuerNameIsValid(false);
      }
    }
   
  }, [issuerName])


  useEffect(() => {
    if (templateName === ""){
      setTemplateNameIsValid(null);
    }else{
      if (templateName.length > 3){
        setTemplateNameIsValid(true)
      }else{
        setTemplateNameIsValid(false);
      }
    }
   
  }, [templateName])


  useEffect(() => {
    if (eventName === ""){
      setEventNameIsValid(null);
    }else{
      if (isEditable == false)
        return
      if (eventName.length > 3){
        setEventNameIsValid(true)
      }else{
        setEventNameIsValid(false);
      }
    }
   
  }, [eventName])



  useEffect(() => {
    if (promoName === ""){
      setPromoNameIsValid(null);
    }else{
      if (isEditable == false)
        return
      if (promoName.length > 3){
        setPromoNameIsValid(true)
      }else{
        setPromoNameIsValid(false);
      }
    }
   
  }, [promoName])


  useEffect(() => {
    if (nameLabel === ""){
      setNameLabelIsValid(null);
    }else{
      if (isEditable == false)
        return
      if (nameLabel.length > 3){
        setNameLabelIsValid(true)
      }else{
        setNameLabelIsValid(false);
      }
    }
   
  }, [nameLabel])

  

  const onSetValueTicket = (event) =>{
    event.preventDefault;

    setTypeTicket(event.value)
    setValueTIcket(event)
  }



  const onSetValueTypeTicket = (event) =>{
    event.preventDefault;

    setTypeCode(event.value)
    setValueTypeCode(event)
  }


  const dropzoneStyle = {
    border: ticketCompanyLogo ? "none" :'2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    height: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  };


  useEffect(() => {

  if (issuerNameIsValid  && ticketCompanyLogo !== null && ticketCompanyBanner != null){
      setFormIsValid(true)
    }else{
      setFormIsValid(false);
    }
  }, [issuerNameIsValid, backgroundColor, ticketCompanyBanner, ticketCompanyLogo])


  useEffect(() => {
    console.log(eventEndDate)
    switch (typeTicket) {
      case 1:
        if (issuerNameIsValid  && backgroundColor !== "#ffffff" && ticketCompanyLogo !== null && ticketCompanyBanner != null && typeCode !== null){
          setFormIsValid(true)
        }else{
          setFormIsValid(false);
        }
        break;
      case 2: 
          if (issuerNameIsValid  && backgroundColor !== "#ffffff" && ticketCompanyLogo !== null && ticketCompanyBanner != null && eventNameIsValid && eventStartDate !== null && eventEndDate !== null && typeCode !== null){
            setFormIsValid(true)
          }else{
            setFormIsValid(false);
          }
      break;
      case 3: 
          if (issuerNameIsValid  && backgroundColor !== "#ffffff" && ticketCompanyLogo !== null && ticketCompanyBanner != null && promoNameIsValid && typeCode !== null && nameLabelIsValid !== null){
            setFormIsValid(true)
          }else{
            setFormIsValid(false);
          }
      break;

      default:
        break;
    }
    
    }, [issuerNameIsValid, backgroundColor,  ticketCompanyLogo, ticketCompanyBanner, eventNameIsValid, eventStartDate, eventEndDate, typeCode, typeTicket, promoNameIsValid, nameLabelIsValid])

  const DropzoneComponent1 = () => {


    const onDrop = useCallback((acceptedFiles) => {
      // Atualiza o estado do avatar
      // console.log(acceptedFiles[0])
      // setAvatar(acceptedFiles[0]);
      // console.log(avatar)
      const file = acceptedFiles[0];

    // Verifica se o arquivo é uma imagem
    if (file && file.type.startsWith('image/')) {
      // Cria uma instância de FileReader para ler as dimensões da imagem
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Verifica se as dimensões são 512x512
          if (file && file.type.startsWith('image/')) {
            Resizer.imageFileResizer(
              file, 
              512, 
              512, 
              'JPEG', 
              100, 
              0, 
              (uri) => {
                // uri é o arquivo redimensionado
                setTicketCompanyLogo(uri);
              },
              'blob' // ou 'base64', dependendo do formato que você deseja
            );
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);

    } else {
      toast.error('Por favor, selecione um arquivo de imagem válido.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      // alert('Por favor, selecione um arquivo de imagem válido.');
    }
    }, []);
    

    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      onDrop,
      multiple: false
    });


    return (
      <>
          <div style={{alignItems: "center", textAlign: "center" }}>
           
            {isEditable != false ? 
            <>
              <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} accept="image/*" disabled={isEditable == false}/>
                    <p style={{margin: "0"}}>Clique aqui e faça o upload</p>
              </div>
            </> : <></>}

            

          

          {ticketCompanyLogo && (
            <div>
              <img
              
                src={ticketCompanyLogo === null ? "" : typeof(ticketCompanyLogo) === "string" ? ticketCompanyLogo : URL.createObjectURL(ticketCompanyLogo)}
                alt="Avatar Preview"
                style={{ maxWidth: '200px', maxHeight: '200px' }}
              />
            </div>
          )}
            </div>
      </>
    );
  };


  const DropzoneComponent2 = () => {

    const onDrop = useCallback((acceptedFiles) => {
      // Atualiza o estado do avatar
      // console.log(acceptedFiles[0])
      // setAvatar(acceptedFiles[0]);
      // console.log(avatar)
      const file = acceptedFiles[0];

    // Verifica se o arquivo é uma imagem
    if (file && file.type.startsWith('image/')) {
      // Cria uma instância de FileReader para ler as dimensões da imagem
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Verifica se as dimensões são 512x512
          if (file && file.type.startsWith('image/')) {
            Resizer.imageFileResizer(
              file, 
              1022, 
              304, 
              'JPEG', 
              100, 
              0, 
              (uri) => {
                // uri é o arquivo redimensionado
                setTicketCompanyBanner(uri);
              },
              'blob' // ou 'base64', dependendo do formato que você deseja
            );
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);

    } else {
      toast.error('Por favor, selecione um arquivo de imagem válido.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      // alert('Por favor, selecione um arquivo de imagem válido.');
    }
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      onDrop,
      multiple: false
    });
    
    
    return (
      <>
          <div style={{alignItems: "center", textAlign: "center"}}>
          
          {isEditable != false ? 
            <>
              <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} accept="image/*" disabled={isEditable == false}/>
                    <p style={{margin: "0"}}>Clique aqui e faça o upload</p>
              </div>
            </> : <></>}
            

          

          {ticketCompanyBanner && (
            <div>
              <img
              
                src={ticketCompanyBanner === null ? "" : typeof(ticketCompanyBanner) === "string" ? ticketCompanyBanner : URL.createObjectURL(ticketCompanyBanner)}
                alt="Banner Preview"
                style={{ maxWidth: '350px', maxHeight: '200px' }}
              />
            </div>
          )}
            </div>
      </>
    );
  };

  const createTemplate = async () => {

    const id = toast.loading('Criando template... ⏳', {
      position: "top-right",
      theme: "light"
      });

    const response = await createTemplateService({ issuerName: issuerName, ticketName: templateName, backgroundColor: backgroundColor, 
      banner: ticketCompanyBanner, logo: ticketCompanyLogo, typeTemplate: typeTicket, typeCode: typeCode, templateName: templateName, 
      organizationId: getItemStorage({key: "companyId"}), eventName:  eventName, startDate: eventStartDate, endDate: eventEndDate,
      promoName: promoName, nameLabel: nameLabel})

      switch (response.statusCode) {
        case 201:
          toast.update(id, {render: "Template criado com sucesso", type: "success", isLoading: false,  autoClose: 2000});

          setTimeout(function() {
            navigate("/templates");
        }, 1000);
          break;
        case 409:
          toast.update(id, {render: response.response.message, type: "warning", isLoading: false, autoClose: 2000});
          setTimeout(function() {
            navigate("/");
        }, 2000);
          break;


        default:
          toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }
  

  }

  const whatForm = () => {

    switch (valueTicket.value) {
      case undefined:
          return <></>
        break;
      case 1:
        return <TransportTikcetTemplateForm isEditable={isEditable}/>
      case 2:
        return <EventTicketTemplateForm eventName={eventName} setEventName={setEventName} eventNameIsValid={eventNameIsValid}
                                        eventStartDate={eventStartDate} setEventStartDate={setEventStartDate} 
                                        eventEndDate={eventEndDate} setEventEndDate={setEventEndDate} isEditable={isEditable}/>
      case 3:
        return <CustomerCardTemplateForm promoName={promoName} setPromoName={setPromoName} promoNameIsValid={promoNameIsValid} isEditable={isEditable}
        nameLabel={nameLabel} setNameLabel={setNameLabel} nameLabelIsValid={nameLabelIsValid}/>
      default:
        
        break;
    }
    console.log(valueTicket.value)
  }

  const TEMPLATES = [
    { value: 1, label: 'Bilhete de transporte' },
    { value: 2, label: 'Ingresso de evento' },
    { value: 3, label: 'Cartão fidelidade' },
  ]


  useEffect(() =>{
    if (typeTicket !== 0 && typeTicket !== undefined){

      var value = TEMPLATES.find(item => item.value === typeTicket);
      setValueTIcket(value);


    }
  }, [typeTicket])


  const CODES = [
    { value: 1, label: 'QR code' },
    { value: 2, label: 'Código de barra' }
  ]

  useEffect(() =>{
    if (typeCode !== 0){
      if (typeCode == undefined){
        var value = CODES.find(item => item.value === 0);
      }else{

      var value = CODES.find(item => item.value === typeCode);
      setValueTypeCode(value);
    }

    }
  }, [typeCode])

  
  return (
    <>
      <h1 className="display-4 text-center mb-3">Template</h1>
      {isEditable != false &&<p className="text-muted text-center mb-5">Crie um template personalizado para seu ticket</p>}
      
      <form>
      <div className="form-group">
        <Form.Label>Template do ticket{" "}

        <FaInfoCircle
              style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
              title={
               
                        "Escolha o modelo do ticket que melhor se encaixa no seu negócio:\n" +
                        "- Bilhete de transporte: ideal para transporte, como ônibus, trens e voos ;\n" +
                        "- Ingresso de evento: perfeito para cinemas, festas, shows e eventos em geral;\n" +
                        "- Cartão fidelidade: recomendado para cartões de fidelidade."
                    }
          
            />
        </Form.Label>

        {isEditable == false ?
        
          <Form.Control value={valueTicket.label} disabled={true}/>
          : <ReactSelect
          options={TEMPLATES}
          placeholder={null}
          onChange={(e) => onSetValueTicket(e)}
          value={valueTicket}
          disabled={isEditable == false}
        />
        }
       
        </div>

      <div className="form-group">
          <Form.Label>
            Nome do template{' '}
                <FaInfoCircle
                  style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                  title="De um nome ao seu template, isso irá ajudá-lo a indentificar mais tarde"
                />
          </Form.Label>
          <Form.Control type="text" placeholder="De um nome ao seu template" onChange={(e) => setTemplateName(e.target.value)} 
          disabled={isEditable == false}
          value={templateName} isInvalid={templateNameIsValid == null ? false : !templateNameIsValid} />
          <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
        </div>  
      <div className="form-group">
          <Form.Label>Nome do emissor{" "}
                <FaInfoCircle
                  style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                  title="Nome do emissor, será o nome da sua empresa no ticket"
                />
          </Form.Label>
          <Form.Control type="text" placeholder="Nome da empresa no ticket" onChange={(e) => setIssuerName(e.target.value)} value={issuerName} 
          isInvalid={issuerNameIsValid == null ? false : !issuerNameIsValid} disabled={isEditable== false}/>
          <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
        </div>  
        <div className="form-group">
        <Form.Label>Logo da empresa (512x512){" "}
                  <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Logo do ticket"
                          />

        </Form.Label>

              {DropzoneComponent1()}
        </div>
        <div className="form-group">
        <Form.Label>{typeTicket == 0 || typeTicket == undefined ? bannerInfo[1].label : bannerInfo[typeTicket].label}{" "}

                          <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title={typeTicket == 0 || typeTicket == undefined  ? bannerInfo[1].title : bannerInfo[typeTicket].title}
                          />
        </Form.Label>

              {DropzoneComponent2()}
        </div>

              {whatForm()}
     
        <div className="form-group">
        <Form.Label>Tipo de código{" "}
                    <FaInfoCircle
                              style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                              title={"QRCode ou código de barra"}
                          
                            />
        </Form.Label>

        {isEditable == false ?
        
        <Form.Control value={valueTypeCode.label} disabled={true}/>
        :         <ReactSelect
        options={CODES}
        placeholder={null}
        onChange={(e) => onSetValueTypeTicket(e)}
        value={valueTypeCode}
      />
      }
        {/* <ReactSelect
              options={CODES}
              placeholder={null}
              onChange={(e) => onSetValueTypeTicket(e)}
              value={valueTypeCode}
            /> */}
        </div>

       {isEditable != false &&          <div className="form-group">
                <Form.Label>Cor do ticket{" "}
                    <FaInfoCircle
                      style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                      title={"Cor de fundo do ticket"}
                  
                    />
                </Form.Label>
                <div style={{display: "flex", justifyContent: "center"}}>
                <CirclePicker color={backgroundColor} 
                onChangeComplete={(e) => setBackgroundColor (e.hex)} disabled={true}/>
          </div>
      </div>}

               {isEditable != false ? <Button size="lg" className="w-100 mb-3" onClick={createTemplate} disabled={!formIsValid}>
          Registrar
        </Button> : <></>}
       
      </form>
      
    </>
  );
}
