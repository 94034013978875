import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { requestLinkRecoveryPasswordService } from '../../Services/userService';
import { validEmail } from '../../utils/stringUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RecoveryPasswordForm() {
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(null);
  let navigate = useNavigate();


  const requestLinkRecoveryPassword = async ()  => {

    const id = toast.loading('Gerando link... ⏳', {
      position: "top-right",
      theme: "light"
      });
    const response = await requestLinkRecoveryPasswordService({email: email});

    switch (response.statusCode) {
      case 200:
        toast.update(id, {render: "Link para recuperação de senha enviado", type: "success", isLoading: false, autoClose: 4000});
        break;
      default:
        toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
        break;
    }

  }


  useEffect(() => {
    if (email === ""){
      setEmailIsValid(null)
    }else{
      let isValidEmail = validEmail({email: email});
      setEmailIsValid(isValidEmail)
    }

  }, [email])



  return (
    <>
      <h1 className="display-5 text-center mb-3">Esqueceu a senha ?</h1>
      <p className="text-muted text-center mb-5">Digite seu e-mail e receba o link de recuperação.</p>
      <form>
        <div className="form-group">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" placeholder="nome@email.com.br" onChange={(e) => setEmail(e.target.value)} isValid={emailIsValid} isInvalid={emailIsValid == null? false : !emailIsValid}/>
        </div>
        <Button size="lg" className="w-100 mb-3" disabled={emailIsValid == null? true : !emailIsValid} onClick={requestLinkRecoveryPassword}>
          Recuperar senha
        </Button>
        <Link to="/">

        <p className="text-center">
          <small className="text-muted text-center">
            Lembra sua senha?{' '}
              <a>Login</a>
            .
          </small>
        </p>
        </Link>

      </form>
      {/* <ToastContainer autoClose={900000000} theme="light"/> */}
    </>
  );
}
