import { Col, Container, Row } from "react-bootstrap";
import RegisterTicketForm from "../../components/RegisterTicketForm/RegisterTicketForm";
import { useEffect, useState } from "react";
import { listTemplatesService } from "../../Services/templateService";
import { getItemStorage } from "../../utils/storage";
import { qrFormContext } from '../../context/context';
import { GOOGLE_JWT } from "../../Resources/text";
import LoyaltyCard from "../../components/qrTemplates/customerCard";
import EventTicket from "../../components/qrTemplates/eventTicket";
import TransportTicket from "../../components/qrTemplates/transportTicket";
import { Link } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function RegisterTicketPage() {

    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [holderName, setHolderName] = useState("");
    const [ticketNumber, setTicketNumber] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [metadata, setMetadata] = useState([{ title: "", value: "" }]);
    const [originName, setOriginName] = useState("");
    const [originCode, setOriginCode] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [originGoogleMapsLink, setOriginGoogleMapsLink] = useState("");
    const [destinationGoogleMapsLink, setDestinationGoogleMapsLink] = useState("");
    const [destinationName, setDestinationName] = useState("");
    const [destinationCode, setDestinationCode] = useState("");  
    const [price, setPrice] = useState("");  
    const [platform, setPlatform] = useState(""); 
    const [seat, setSeat] = useState("");          
    const [eventLocal, setEventLocal] = useState(""); 
    const [eventLocalMapsLink, setEventLocalMapsLink] = useState(""); 
    const [gate, setGate] = useState("");
    const [section, setSection] = useState("");
    const [qrCodeValue, setQrCodeValue] = useState("");
    const [row, setRow] = useState("");
    const getNameByType = ({template}) => {
        console.log(template.typeTemplate)
        switch (template.typeTemplate) {
            case 1:
                return ""
            case 2:
                return `- ${template.eventName}`
            case 3:
                return `- ${template.promoName}`
            default:
                return ""
        }
    }


    const TemplateTicket = () =>{
    
        
        switch (template.typeTemplate) {
          case 1:
            return <TransportTicket typeCode={template.typeCode} issuerName={template.issuerName}
                                    codeValue={"TEMPLATE"}
                                    metadata={metadata}
                                    companyLogo={template.logoUrl}
                                    origin={originName}
                                    destination={destinationName}
                                    originDate={startDate}
                                    destinationDate={endDate}
                                    ticketNumber={ticketNumber}
                                    passenger={holderName}
                                    backgroundColor={template.backgroundColor}
                                    googleJwt={GOOGLE_JWT}
                                    originCode={originCode}
                                    destinationCode={destinationCode}
                                    platform={platform}
                                    seat={seat}
                                    
                                    />
    
          case 2:
            //EVENTO
            return <EventTicket issuerName={template.issuerName} eventName={template.eventName} eventDate={new Date(template.timeInterval.startDate)}
                                metadata={metadata}
                                identifier={ticketNumber}
                                userName={holderName}
                                companyLogo={template.logoUrl}
                                backgroundColor={template.backgroundColor}
                                typeCode={template.typeCode}
                                googleJwt={GOOGLE_JWT}
                                codeValue={"TEMPLATE"}
                                />
    
    
          case 3:
            // Cartão fidelidade
            return <LoyaltyCard issuerName={template.issuerName} 
                                 backgroundColor={template.backgroundColor}
                                  promoName={template.promoName}
                                  metadata={metadata}
                                  identifier={ticketNumber}
                                  userName={holderName}
                                  companyLogo={template.logoUrl}
                                  codeValue={"TEMPLATE"}
                                  typeCode={template.typeCode}
                                  googleJwt={GOOGLE_JWT}
                                  nameLabel={template.nameLabel}
                                  />
    
          default:
            
            break;
        }
      }
    
    const  listTemplates = async () =>{

    const response = await listTemplatesService({organizationId: getItemStorage({key: "companyId"})})

                switch (response.statusCode) {
                    case 200:

                    //   toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});
                    
                        console.log(response.response.templates);

                        //setTableData((prevData) => [...prevData, ...response.response.templates]);


                        const newData = response.response.templates.map(x => ({ value: x.id, 
                                                                                label: `${x.id.split("-")[0]} - ${x.templateName} - ${x.typeTemplate} ${getNameByType({template: x})}`, 
                                                                                typeTemplate: x.typeTemplate,
                                                                                template: x}));
                        // console.log(newData)
                        setTemplates(newData);   

                    break;
                    case 409:
                    //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
                    break;
                    default:
                    //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
                    break;
      }


    }
    useEffect(() => {
        
        listTemplates();
        
    }, [])


    
  
    return (
    <>
    <qrFormContext.Provider value={{ templates,
                                      template, setTemplate,
                                      holderName, setHolderName,
                                      ticketNumber, setTicketNumber,
                                      contactPhone, setContactPhone,
                                      contactEmail, setContactEmail,
                                      metadata, setMetadata,
                                      originName, setOriginName,
                                      originCode, setOriginCode,
                                      startDate, setStartDate,
                                      endDate, setEndDate,
                                      originGoogleMapsLink, setOriginGoogleMapsLink,
                                      destinationGoogleMapsLink, setDestinationGoogleMapsLink,
                                      destinationName, setDestinationName,
                                      destinationCode, setDestinationCode,
                                      platform, setPlatform,
                                      seat, setSeat,
                                      price, setPrice,
                                      eventLocal, setEventLocal,
                                      eventLocalMapsLink, setEventLocalMapsLink,
                                      gate, setGate,
                                      section, setSection,
                                      qrCodeValue, setQrCodeValue,
                                      row, setRow
                                      

                                  }}>


                                    <Link to={"/qrcodes"}>
                                        <IoIosArrowRoundBack style={{fontSize: "50px", marginLeft: "10px"}}/>
                                    </Link>
    <div className="d-flex align-items-center min-vh-100 bg-auth border-primary">
    <Container>
                <Row>

                {
            template != null ? (

                <Col xs={12} md={6} className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 custom-style-for-type-0" 
                    style={{  
                        // position: "-webkit-sticky",
                        // position: "sticky",
                        // zIndex: 1000,
                        // backgroundColor: "pink",
                        
                        
                        }}>

                        <div style={{ position: "sticky", top: "5%", bottom: "8%"}}>
                            {TemplateTicket()}
                        </div>
                </Col>

            ) : null
        }



                <Col xs={12} md={5} xl={4} className="order-md-1 my-5">
                        <RegisterTicketForm isEditable={true}/>
                </Col>  
         </Row>
        </Container>
    </div>
    
    
    
    </qrFormContext.Provider>
    
    
    </>);



}