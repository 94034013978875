export function validEmail({email}) {
    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return pattern.test(email);
  }



export function validPassword({password}) {
    var pattern = /^(?=.*[!@#$%^&*?])[a-zA-Z0-9!@#$%^&*?]{6,}$/;

    return pattern.test(password);
  }


export function isValidWebsite(website) {
    // Expressão regular para verificar o formato básico de uma URL
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]+)?(\/.*)?$/;
  
    // Testa se a URL fornecida corresponde ao formato esperado
    return urlRegex.test(website);
}

export function isValidCEP({cep}) {
  // Expressão regular para validar CEP brasileiro

  return /^\d{5}-\d{3}$/.test(cep);
}


export function validPhoneNumber({phoneNumber}) {
  // Expressão regular para validar números de telefone no formato (XX) 9XXXX-XXXX para celulares
  // ou (XX) XXXX-XXXX para telefones fixos.
  var regex = /^\(\d{2}\) 9?\d{4,5}-\d{4}$/;

  // Testa se o número passado atende à expressão regular
  return regex.test(phoneNumber);
}


export function isValidGoogleMapsLink({link}) {

  // Padrão de link do Google Maps

  if (link.startsWith("https://www.google.com/maps/place/")) {
    return true;
  }
  const googleMapsPattern = /^(https?:\/\/)?(www\.)?(maps\.google\.com|goo\.gl|maps\.app\.goo\.gl)\/(.*\/)?(place\/)?[^\/?@]+(@[-0-9.]+,[-0-9.]+,\d+(z\/)?)?.*$/;

  return googleMapsPattern.test(link);
}
