import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { IoQrCode } from "react-icons/io5";

export default function QuantityQRCode({ ...props }) {
  return (
    <Card style={{minHeight: "144px"}}>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Quantidade de QrCodes gerados</h6>
            <span className="h2 mb-0">5</span>
          </Col>
          <Col xs="auto">
            <IoQrCode size={30} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
