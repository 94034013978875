import { Col, Container, Row } from 'react-bootstrap';
import RegisterOrganizationForm from '../components/RegisterOrganization/RegisterOrganizationForm';
import { useEffect, useState } from 'react';
import { formContext } from '../context/context';
import { checkUserInOrganizationService } from '../Services/userService';
import { useNavigate } from 'react-router-dom';
import { setItemStorage } from '../utils/storage';

export default function RegisterOrganizationPage() {
  
  const [companyName, setCompanyName] = useState("");
  const [companyZip, setCompanyZip] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [userInOrganization, setUserInOrganization] = useState(true);

  const navigate = useNavigate();

  const checkUserInOrganization = async () => {
      const inOrganization =  await checkUserInOrganizationService();
      console.log(inOrganization.response)
        if(inOrganization.response.inOrganization){


            navigate("/");
        }else{
          setUserInOrganization(false);
        }
  }

  useEffect(() => {
    checkUserInOrganization();
  }, [])
  


  
  return (
    <>
    {userInOrganization ? <></> : 

    <formContext.Provider value={{ companyName, setCompanyName, 
                                  companyZip, setCompanyZip,
                                  companyWebsite, setCompanyWebsite,
                                  companyDescription, setCompanyDescription,
                                  companyEmail, setCompanyEmail,
                                  avatar, setAvatar
                                  }}>

    <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
     <Container>
     <Row className={"justify-content-center"}>

          <Col xs={12} md={5} xl={4} className={"my-5"}>
              <RegisterOrganizationForm />
          </Col>  
      
      </Row>


    </Container>





    </div>
    </formContext.Provider>
    }
    </>
  );
}
