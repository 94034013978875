import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useDropzone } from "react-dropzone";
import { FaInfoCircle } from "react-icons/fa";
import { NumberFormatBase, NumericFormat, PatternFormat } from "react-number-format";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import FLATPICKR_LANGUAGE from "flatpickr/dist/l10n/pt.js";
import { isValidGoogleMapsLink } from "../../utils/stringUtils";

export default function TransportTikcetForm({ticketNumber, setTicketNumber, ticketNumberIsValid, originName, setOriginName, originCode, setOriginCode,
    destinationName, setDestinationName,
    destinationCode, setDestinationCode, 
    startDate, 
    setStartDate,
    endDate, setEndDate,
    originGoogleMapsLink, setOriginGoogleMapsLink,
    destinationGoogleMapsLink, setDestinationGoogleMapsLink,
    platform, setPlatform,
    seat, setSeat,
    price, setPrice,
    originNameIsValid, 
    originCodeIsValid, 
    destinationNameIsValid, 
    destinatioCodeIsValid, 
    originGoogleMapsLinkIsValid, 
    destinationGoogleMapsLinkIsValid, 
    platformIsValid, 
    seatIsValid,
    isEditable
}) {    


    return (
        <> 
            <div className="form-group">
                        <Form.Label>
                            Número do ticket<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Número do ticket"
                                />
                        </Form.Label>
                        <Form.Control required type="text" disabled={!isEditable} placeholder="Número do ticket" onChange={(e) => setTicketNumber(e.target.value)} 
                        value={ticketNumber} isInvalid={ticketNumberIsValid == null ? false : !ticketNumberIsValid} 
                        isValid={ticketNumberIsValid == null ? false : ticketNumberIsValid}/>
                        <Form.Control.Feedback type="invalid">Número de ticket inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                            Origem<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome do lugar de origem"
                                />
                        </Form.Label>
                        <Form.Control required type="text" disabled={!isEditable} placeholder="Local de sáida" onChange={(e) => setOriginName(e.target.value)} 
                        value={originName} isInvalid={originNameIsValid == null ? false : !originNameIsValid}
                        isValid={originNameIsValid == null ? false : originNameIsValid}/>
                        <Form.Control.Feedback type="invalid">Nome de origem inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                            Sigla da origem{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Sigla do local de origem"
                                />
                        </Form.Label>
                        <Form.Control type="text" placeholder="Sigla do local de origem" disabled={!isEditable} onChange={(e) => setOriginCode(e.target.value)} 
                        value={originCode} isInvalid={originCodeIsValid == null ? false : !originCodeIsValid} 
                        isValid={originCodeIsValid == null ? false : originCodeIsValid}/>
                        <Form.Control.Feedback type="invalid">Sigla inválida, deve ter menos que 4 caracteres</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                        Hora de saída<span style={{ color: 'red' }}>*</span>{' '}
                        <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Dia e horário que da saída"
                        />
                        </Form.Label>
                        <Form.Control required as={Flatpickr} options={{ minDate: new Date(),
                                                                enableTime: true,
                                                                locale: FLATPICKR_LANGUAGE.pt,
                                                                dateFormat: "d-m-Y H:i", 
                                                                allowInput: true, 
                                                                time_24hr: true
                                                                }}className="mb-3" placeholder="Horário de embarque" 
                                                                value={startDate}
                                                                onChange={e => setStartDate(e[0])}
                                                                onKeyDown={(e)=>{if(e.key === "Backspace") setStartDate(null)}}
                                                                disabled={!isEditable}
                                                                isValid={startDate !== null}
                                                                />

                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
            
            <div className="form-group">
                        <Form.Label>
                            Google Maps de origem<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Link do Google Maps do local de embarque"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Link do Google Maps de origem" onChange={(e) => setOriginGoogleMapsLink(e.target.value)} 
                        value={originGoogleMapsLink} isInvalid={originGoogleMapsLinkIsValid == null ? false : !originGoogleMapsLinkIsValid}
                        disabled={!isEditable}
                        isValid={originGoogleMapsLinkIsValid == null ? false : originGoogleMapsLinkIsValid}
                        />
                        <Form.Control.Feedback type="invalid">Link inválido</Form.Control.Feedback>
            </div>
            
            <div className="form-group">
                        <Form.Label>
                            Destino<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome do local de destino"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Local de destino" onChange={(e) => setDestinationName(e.target.value)} 
                                                                                        disabled={!isEditable}

                        value={destinationName} isInvalid={destinationNameIsValid == null ? false : !destinationNameIsValid} 
                        isValid={destinationNameIsValid == null ? false : destinationNameIsValid}
                        />
                        <Form.Control.Feedback type="invalid">Nome de destino inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                                        Sigla do destino{' '}
                                            <FaInfoCircle
                                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                            title="Sigla do local de destino"
                                            />
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Sigla do local de destino" onChange={(e) => setDestinationCode(e.target.value)} 
                                    value={destinationCode} isInvalid={destinatioCodeIsValid == null ? false : !destinatioCodeIsValid}
                                    disabled={!isEditable}

                                    isValid={destinatioCodeIsValid == null ? false : destinatioCodeIsValid}
                                    />
                        <Form.Control.Feedback type="invalid">Sigla inválida, deve ter menos que 4 caracteres</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                        Previsão de chagada<span style={{ color: 'red' }}>*</span>{' '}
                        <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Dia e horário previsto para desembarque"
                        />
                        </Form.Label>
                        <Form.Control required as={Flatpickr} options={{ minDate: startDate  ,
                                                                enableTime: true,
                                                                locale: FLATPICKR_LANGUAGE.pt,
                                                                dateFormat: "d-m-Y H:i", 
                                                                allowInput: true, 
                                                                time_24hr: true
                                                                }}className="mb-3" placeholder="Horário de chegada" 
                                                                value={endDate}
                                                                onChange={e => setEndDate(e[0])}
                                                                onKeyDown={(e)=>{if(e.key === "Backspace") setEndDate(null)}}
                                                                isValid={endDate !== null}
                                                                disabled={!isEditable}

                                                                // disabled={isEditable == false}

                                                                />

                        <Form.Control.Feedback type="invalid">Data inválida deve ser diferente da data de embarque</Form.Control.Feedback>
            </div>
            
            <div className="form-group">
                        <Form.Label>
                        Google Maps do destino<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Link do Google Maps do local de desembarque"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Link do Google Maps de origem" 
                        onChange={(e) => setDestinationGoogleMapsLink(e.target.value)}
                        isInvalid={destinationGoogleMapsLinkIsValid == null ? false : !destinationGoogleMapsLinkIsValid}
                        isValid={destinationGoogleMapsLinkIsValid == null ? false : destinationGoogleMapsLinkIsValid}
                        value={destinationGoogleMapsLink}
                        disabled={!isEditable}

                        />
                        <Form.Control.Feedback type="invalid">Link inválido, deve ser link do Google Maps e diferente do link de destino</Form.Control.Feedback>
            </div>
            
            <Container>
                        <Row className="align-items-center mb-3">
                            <Col style={{minWidth: "35%" }}>
                            <div className="form-group">
                                        <Form.Label>
                                                Plataforma<span style={{ color: 'red' }}>*</span>{' '}
                                                        {/* <FaInfoCircle
                                                        style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                                        title="Nome do portador, aparecerá no ticket"
                                                        /> */}
                                                </Form.Label>

                                            <Form.Control
                                            required
                                            type="text"
                                            placeholder="Plataforma"
                                            style={{ width: "100%" }}
                                            onChange={(e) => setPlatform(e.target.value)}
                                            isInvalid={platformIsValid == null ? false : !platformIsValid}
                                            isValid={platformIsValid == null ? false : platformIsValid}
                                            value={platform}
                                            disabled={!isEditable}


                                            />
                                </div>
                            </Col>

                            <Col style={{minWidth: "25%"  }}>

                            <div className="form-group">
                            <Form.Label>
                                    Poltrona<span style={{ color: 'red' }}>*</span>{' '}
                                            {/* <FaInfoCircle
                                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                            title="Nome do portador, aparecerá no ticket"
                                            /> */}
                                    </Form.Label>
                                <Form.Control
                                required
                                type="text"
                                placeholder="Poltrona"
                                style={{ width: "100%" }}
                                onChange={(e) => setSeat(e.target.value)}

                                isInvalid={seatIsValid == null ? false : !seatIsValid}
                                isValid={seatIsValid == null ? false : seatIsValid}
                                value={seat}
                                disabled={!isEditable}

                                />
                                </div>
                            </Col>
                            <Col style={{minWidth: "40%"  }}>
                            <div className="form-group">
                        <Form.Label>
                        Preço<span style={{ color: 'red' }}>*</span>{' '}
                                {/* <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome do portador, aparecerá no ticket"
                                /> */}
                        </Form.Label>

                        <Form.Control required as={NumericFormat}  
                                            placeholder="Valor"
                                            prefix="R$ "
                                            // fixedDecimalLength={2}
                                            decimalScale={2}
                                            decimalsLimit={2}
                                            decimalSeparator=","
                                            fixedDecimalScale={true}
                                            allowLeadingZeros
                                            // decimalSeparator=","
                                            // groupSeparator="."
                                            // onValueChange={(value, name, values) => console.log(value, name, values)}
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            
                                             isValid={price > 0}
                                             disabled={!isEditable}

                                            />
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
                            </Col>

                        </Row>
            </Container>
          
            
        </>
    )
}