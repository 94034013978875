import { getItemStorage } from '../utils/storage';
import api from './api';
import { useRefreshToken } from './refreshToken';

export const createTicketService = async ({ holderName, templateId, phoneNumber, email, metadata, qrCodeValue, organizationId,
startDate, endDate, seat, platform, price, originName, originCode, destinationName, destinationCode, originMapsLink, destinationMapsLink,
ticketNumber, eventLocalName, eventLocalMapsLink, row, section, gate}) => {
  try {



    var data = {
      "holderName": holderName,
      "templateId": templateId,
      "phoneNumber": phoneNumber,
      "email": email,
      "metadata": metadata.filter(item => item.title !== '' || item.value !== ''),
      "qrCodeValue": qrCodeValue,
      "organizationId": organizationId,
      "startDate": startDate,
      "endDate": endDate,
      "seat": seat, 
      "platform": platform,
      "price": price,
      "originName": originName,
      "originCode": originCode,
      "destinationName": destinationName,
      "destinationCode": destinationCode,
      "originMapsLink": originMapsLink,
      "destinationMapsLink": destinationMapsLink,
      "ticketNumber": ticketNumber,
      "eventLocalName": eventLocalName,
      "eventLocalMapsLink": eventLocalMapsLink,
      "gate": gate,
      "row": row,
      "section": section
      
    }


    for (var prop in data) {
      // Verifica se a propriedade tem valor nulo
      if (data[prop] === null || data[prop] === "") {
        // Remove a propriedade do objeto
        delete data[prop];
      }
    }


  
    console.log("aqui")

    // console.log(form.entries())
    const response = await api.post("/ticket", data, {
      headers: {
          Authorization:`Bearer ${getItemStorage({
              key: "@QRConnect:Prod:At",
            })}`
      }});
    
    if (response.status === 201) {
      return { "response": response.data.data, "statusCode": response.status };
    }
  } catch (error) {
    console.log(error)
    if (error.response.status == 401) {
      await useRefreshToken();

      return await createTicketService({ holderName: holderName, email: email, metadata: metadata, phoneNumber: phoneNumber,
        qrCodeValue: qrCodeValue, templateId: templateId,
        startDate: startDate, endDate : endDate, seat:seat, platform: platform, price: price, originName: originName,
         originCode: originCode, destinationName: destinationName, destinationCode: destinationCode, originMapsLink: originMapsLink, 
         destinationMapsLink: destinationMapsLink, ticketNumber: ticketNumber, eventLocalName: eventLocalName, eventLocalMapsLink: eventLocalMapsLink,
         gate: gate, row: row, section: section,
        organizationId: organizationId}
      );
    }
    // console.log(error)
    return { "response": error.response.data, "statusCode": error.response.status };
  }
};

export const listTicketService = async ({ organizationId }) => {
    try {


      const response = await api.get(`/tickets?organizationId=${organizationId}`, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 200) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await listTicketService({ organizationId: organizationId });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };



  export const getTicketByIdService = async ({ organizationId, ticketId}) => {
    try {

      const response = await api.get(`/ticket?organizationId=${organizationId}&ticketId=${ticketId}`, {
        headers: {
            Authorization:`Bearer ${getItemStorage({
                key: "@QRConnect:Prod:At",
              })}`
        }});
      
      if (response.status === 200) {
        return { "response": response.data.data, "statusCode": response.status };
      }
    } catch (error) {
      if (error.response.status == 401) {
        await useRefreshToken();
  
        return await listTicketService({ organizationId: organizationId, ticketId: ticketId });
      }
      // console.log(error)
      return { "response": error.response.data, "statusCode": error.response.status };
    }
  };




export const getTicketByExternalIdService = async ({ externalId}) => {
  try {

    const response = await api.get(`/ticket/external?externalId=${externalId}`);
    
    if (response.status === 200) {
      return { "response": response.data.data, "statusCode": response.status };
    }
  } catch (error) {
    if (error.response.status == 401) {
      await useRefreshToken();

      return await getTicketByExternalIdService({ externalId: externalId});
    }
    // console.log(error)
    return { "response": error.response.data, "statusCode": error.response.status };
  }
};