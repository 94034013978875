import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import FLATPICKR_LANGUAGE from "flatpickr/dist/l10n/pt.js"; // Importando o idioma Português
import { Form } from "react-bootstrap";
import { FaInfoCircle } from 'react-icons/fa';

export default function EventTicketTemplateForm({eventName, setEventName, eventNameIsValid, eventStartDate, setEventStartDate, eventEndDate, setEventEndDate,
isEditable}) {
    console.log(eventStartDate)
    console.log("xxxxxxxxxxxxx")
    console.log(eventEndDate)
    return (
         <>

            <div className="form-group">
                <Form.Label>
                            Nome do evento{' '}
                            <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Nome do evento, para cada evento é necessário criar um template"
                            />
                </Form.Label>
                <Form.Control type="text" placeholder="Nome do seu evento" onChange={(e) => setEventName(e.target.value)}  value={eventName}
                               isInvalid={eventNameIsValid == null ? false : !eventNameIsValid}
                               disabled={isEditable == false}
                               />
                <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>  
            <div className="form-group">
                        <Form.Label>
                        Início do evento{' '}
                        <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Dia e horário que o evento começa"
                        />
                        </Form.Label>
                        <Form.Control as={Flatpickr} options={{ minDate: new Date(),
                                                                enableTime: true,
                                                                locale: FLATPICKR_LANGUAGE.pt,
                                                                dateFormat: "d-m-Y H:i", 
                                                                allowInput: true, 
                                                                time_24hr: true
                                                                }}className="mb-3" placeholder="Início do evento" 
                                                                value={eventStartDate}
                                                                onChange={e => setEventStartDate(e[0])}
                                                                onKeyDown={(e)=>{if(e.key === "Backspace") setEventStartDate(null)}}
                                                                disabled={isEditable == false}

                                                                />

                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
            <div className="form-group">
                        <Form.Label>
                        Término do evento{' '}
                        <FaInfoCircle
                            style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                            title="Dia e horário que o evento termina"
                        />
                        </Form.Label>
                        <Form.Control as={Flatpickr} options={{ minDate: eventStartDate == null ? new Date(): eventStartDate,
                                                                enableTime: true,
                                                                locale: FLATPICKR_LANGUAGE.pt,
                                                                dateFormat: "d-m-Y H:i", 
                                                                allowInput: true, 
                                                                time_24hr: true
                                                                }}className="mb-3" placeholder="Término do evento" 
                                                                value={eventEndDate}
                                                                onChange={e => setEventEndDate(e[0])}
                                                                on
                                                                onKeyDown={(e)=>{if(e.key === "Backspace") setEventEndDate(null)}}
                                                                disabled={isEditable == false}
                                                                />
                                                                

                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>  


            </>
        
    )
}