import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { changePasswordService, checkURLSerializedService } from '../../Services/userService';
import { validEmail, validPassword } from '../../utils/stringUtils';
import { toast } from 'react-toastify';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export default function ChangePasswordForm() {
  
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [urlSerializedIsValid, setUrlSerializedIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  let navigate = useNavigate();
  const {urlSerialized} = useParams();



  const checkURLSerialized = async ()  => {


    const response = await checkURLSerializedService({urlSerialized: urlSerialized});

    switch (response.statusCode) {
      case 204:
        setUrlSerializedIsValid(true);
        break;
      default:
        setUrlSerializedIsValid(false);
        toast.error('Link expirado, solicite outro novamente!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        // toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
        break;
    }

  }

  const changePassword = async ()  => {

    const id = toast.loading('Alterando senha... ⏳', {
      position: "top-right",
      theme: "light"
      });
    const {response, statusCode} = await changePasswordService({password: password, urlSerialized: urlSerialized});

    switch (statusCode) {
      case 204:
        toast.update(id, {render: "Senha alterada com sucesso", type: "success", isLoading: false, autoClose:2000});
        setTimeout(function() {
          navigate("/");
      }, 1000);
        break;
      default:
        toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
        break;
    }

  }


  useEffect(() => {
    if (password === ""){
      setPasswordIsValid(null)
    }else{
      let isvalid = validPassword({password: password});
      setPasswordIsValid(isvalid)
    }

  }, [password])

    useEffect(() => {
      checkURLSerialized();

    }, [])


    useEffect(() => {
      if(passwordIsValid && urlSerializedIsValid){
        setFormIsValid(true);
      }else{
        setFormIsValid(false);
      }
  
    }, [passwordIsValid, urlSerializedIsValid])

  return (
    <>
      <h1 className="display-5 text-center mb-3">Recuperação de senha</h1>
      <p className="text-muted text-center mb-5">Digite sua nova senha.</p>
      <form>
        <div className="form-group">
        <InputGroup className="input-group-merge">
          {/* <Form.Label>Senha</Form.Label> */}
          <Form.Control type={hidePassword ? "password" : "text"} placeholder="Digite sua nova senha" onChange={(e) => setPassword(e.target.value)} isValid={passwordIsValid} isInvalid={passwordIsValid == null? false : !passwordIsValid} disabled={!urlSerializedIsValid}/>
          <InputGroup.Text>
              <FeatherIcon icon="eye" size="1em" onClick={()=> setHidePassword(!hidePassword)} disabled={!urlSerializedIsValid}/>
            </InputGroup.Text>
          <Form.Control.Feedback type="invalid">Senha deve ter 6+ caracteres alfanuméricos e pelo menos 1 caractere especial: !@#$%*&.</Form.Control.Feedback>
          </InputGroup>
        </div>
        <Button size="lg" className="w-100 mb-3" disabled={!formIsValid} onClick={changePassword}>
          Recuperar senha
        </Button>
        <Link to="/">

        <p className="text-center">
          <small className="text-muted text-center">
            Lembra sua senha?{' '}
              <a>Login</a>
            .
          </small>
        </p>
        </Link>

      </form>
      
    </>
  );
}
