import { getItemStorage, removeItemStorage, setItemStorage } from '../utils/storage';
import api from './api';


export const refreshToken = async () => {
    try {
  
      const response = await api.post("/user/refreshToken", {}, {
        headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${getItemStorage({key: "@QRConnect:Prod:At"})}`,
        "Refreshtoken": getItemStorage({key: "@QRConnect:Prod:Rt"})
      }});
  
      return response.data;
    } catch (error) {
      return null;
    }
  };

export const useRefreshToken = async () => {
    const token = getItemStorage({key: "@QRConnect:Prod:At"});
    console.log(token)
    let response;
    
    if (token) {

      response = await refreshToken();
      console.log(token)
      if (response != null) {
        setItemStorage({key: "@QRConnect:Prod:At", value: response.accessToken})
        setItemStorage({key: "@QRConnect:Prod:Rt", value: response.refreshToken})
        return;
      }

    }
  
    removeItemStorage({key: "@QRConnect:Prod:At"})
    removeItemStorage({key: "@QRConnect:Prod:Rt"})
    //window.location.href = "/login";

  
    return;
  };