import { cacheReducerTemplates } from './Templates';
import { configureStore } from '@reduxjs/toolkit'

import { combineReducers } from 'redux';
import { cacheReducerTickets } from './QRCode';

const rootReducer = combineReducers({
  cahceTemplates: cacheReducerTemplates,
  cacheTickets: cacheReducerTickets
});


//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = configureStore({
  reducer: rootReducer
});

export default store;