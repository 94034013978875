import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { getContrastColor } from '../../utils/color';
import { TicketCodeZone, TicketContainer, TicketDivider, TicketHeader, TicketHolder, TicketSection } from './styles';
import { getMonthName } from '../../utils/dateUtils';
import Barcode from 'react-barcode';
import ButtonsSaveInWallet from '../ButtonsSaveInWallet';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { IoMdDownload } from "react-icons/io";
import generatePDF, {  } from 'react-to-pdf';
import { PDF_OPTION } from '../../Resources/pdf';
import { ToastContainer, toast } from 'react-toastify';

const EventTicket = ({issuerName, companyLogo, eventName, eventDate, eventLocation, metadata, identifier, userName, backgroundColor,
  typeCode, googleJwt, codeValue, gate, section,row, seat, isExtern=false}) => {


  const sectionInfoStyle = {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    paddingTop: '16px',
  };

  const airlineCodeStyle = {
    fontSize: '17px',
    color: getContrastColor({background: backgroundColor}),
    marginBottom: '16px',
    fontWeight: "bold"
  };


  const movieInfoStyle = {
    marginBottom: '8px',
  };

  const passengerTitleStyle = {
    fontWeight: '500',
    // marginBottom: '4px',
    color: getContrastColor({background: backgroundColor})
  };

     
  const passengerInfoStyle = {
    fontSize: '18px',
    color: getContrastColor({background: backgroundColor}),
  };
  
  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '16px',
  };



  const getTargetElement = () => document.getElementById('cardContainer');

  const filename = eventName.replace(" ", "_").replace(/ /g, "_")  + "-" + userName.replace(/ /g, "_") + '.pdf'

  const options = PDF_OPTION({fileName: filename})
  console.log(companyLogo)


  const donwloadPDF = () => {
    
    const id = toast.loading('Gerando PDF... ⏳', {
      position: "top-right",
      });

      generatePDF(getTargetElement, options)

      toast.update(id, {render: "PDF gerado com sucesso", type: "success", isLoading: false, autoClose: 2000});
  }


  return (

    <div >

    <TicketContainer backgroundColor={backgroundColor} id="cardContainer">
      <TicketHeader>
        <img
                  src={companyLogo === null ? "https://placehold.co/512x512": typeof(companyLogo) === "string" ? companyLogo : URL.createObjectURL(companyLogo)}
                  alt={issuerName + " logo"}
              style={{ height: '50px' }}
        />
        <h2 style={{ margin:0, fontSize: '18px', fontWeight: '500', marginLeft:"15px", color: getContrastColor({background: backgroundColor}) }}>{issuerName}</h2>
        {isExtern &&  <div id="buttonDownload" data-html2canvas-ignore="true" style={{ marginLeft: "auto", cursor: "pointer" }} onClick={donwloadPDF}>
                    <IoMdDownload style={{ fontSize: "30px", marginRight: "10px", color: getContrastColor({ background: backgroundColor })}} />
                  </div>}
      </TicketHeader>
      <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

      <TicketSection>
        <div>
        <p style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}) }}>{eventName}</p>
          <div style={movieInfoStyle}>
            <p style={{ fontSize: '22px',fontWeight: '500',margin: '0', color: getContrastColor({background: backgroundColor})}}>Horário: {eventDate.getHours().toString() + ":" + eventDate.getMinutes().toString().padStart(2, '0')}</p>
            <p style={{ fontSize: '15px', fontWeight: '500', margin: '0' , color: getContrastColor({background: backgroundColor}) }}>{eventDate.getDate().toString() + " de " + getMonthName({monthNumber: eventDate.getMonth()+1}).toString() + " de " + eventDate.getFullYear().toString()}</p>

            <div style={sectionInfoStyle}>
            {metadata.map((item, index) => (
                <React.Fragment key={index}>
                  {item.title !== "" ? <>
                  <span style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>{`${item.title}: ${item.value}`}</span>
                  <br />
                  </>: <></>}
                </React.Fragment>
              ))}
          </div>


              {
                ((gate != null && gate !== "") || (section != null && section !== "") || (row != null && row !== "") || (seat != null && seat !== "")) &&
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: "10px" }}>
                      {[
                        { label: 'Assento', value: seat },
                        { label: 'Fileira', value: row },
                        { label: 'Seção', value: section },
                        { label: 'Portão', value: gate },
                      ].map((item, index) => (
                        item.value && (
                          <div key={index} style={{ display: 'flex', flexDirection: 'row', marginRight: '10px', width: "40%"}}>
                            <span style={{ fontSize: '18px', fontWeight: "500",color: getContrastColor({ background: backgroundColor }) }}>
                              {`${item.label}: ${item.value}`}
                            </span>
                            <br />
                          </div>
                        )
                      ))}
                    </div>

              }
      



          
          </div>
        </div>
      </TicketSection>


           

      {/* <TicketSection>
        <div>






          
        </div>


      </TicketSection> */}
      
      <TicketSection >
            <span style={airlineCodeStyle}>Número do bilhete </span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}), fontWeight: 'bold' }}>{identifier}</span>
            </div>
      </TicketSection>


      <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

      <TicketHolder>
            <h3 style={passengerTitleStyle}>Portador(a)</h3>
            <div>
              <p style={passengerInfoStyle}>{userName}</p>
              {/* <p style={{ fontSize: '12px', color: '#aaa' }}>20 kg., In-flight meal</p> */}
            </div>
          </TicketHolder>




          <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

          <TicketCodeZone >
            
          {typeCode === 1 || typeCode === null ? 
                                  <div style={{ background: 'white', padding: '18px', borderRadius: "20px", width: "max-content" }}>
                                  <QRCode value={codeValue} size={200} /> </div>:  
                                  <Barcode value={codeValue} width={2.5} displayValue={false}/>
            }

          </TicketCodeZone>

          <div id='buttonZone' data-html2canvas-ignore="true">
                <div style={buttonContainerStyle}>
                                    <ButtonsSaveInWallet googleJwt={googleJwt}/>
                </div>
          </div>



     
    </TicketContainer>
    

    </div>
  );
};

export default EventTicket;
