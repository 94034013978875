function calculateLuminance(color) {
    color = color.replace(/^#/, '');
    const rgb = parseInt(color, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;

    // Fórmula para calcular a luminância
    return 0.2126 * r / 255 + 0.7152 * g / 255 + 0.0722 * b / 255;
}


export function getContrastColorx({background}) {
    // Calcula a luminância do fundo
  

    const luminance = calculateLuminance(background);

    // Escolhe a cor do texto com base no contraste
    const textColor = luminance < 0.5 ? "#000000" : "#ffffff";
    
    return textColor;
}


export function getContrastColor({background}) {
    // Converting hex color to RGB
    const color = {
        R: parseInt(background.slice(1, 3), 16),
        G: parseInt(background.slice(3, 5), 16),
        B: parseInt(background.slice(5, 7), 16),
    };

    let d = 0;

    // Calculating perceptive luminance - human eye favors green color...
    const luminance = (0.299 * color.R + 0.587 * color.G + 0.114 * color.B) / 255;

    if (luminance > 0.5) {
        d = 0; // Bright colors - black font
    } else {
        d = 255; // Dark colors - white font
    }

    // Converting RGB to hex
    const resultHexColor = `#${d.toString(16).padStart(2, '0').repeat(3)}`;

    return resultHexColor;
}

// // Example usage:
// const inputHexColor = '#808080'; // Replace with your desired hex color
// const resultHexColor = calculateColorContrast(inputHexColor);
// console.log(resultHexColor);

