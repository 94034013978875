import { IoQrCode } from "react-icons/io5";
import { LuLayoutTemplate } from "react-icons/lu";
import { MdDashboard } from "react-icons/md";
import { SlDocs } from "react-icons/sl";

const NAVBARITEMS = {
    base: {
      children: ['dashboards', 'qrcodes', 'templates', "documentation"],
      id: 'base',
    },
    
    dashboards: {
      icon: <MdDashboard size={20}/>      ,
      id: 'dashboards',
      title: 'Dashboards',
      url: '/',
    },
    qrcodes: {
      icon: <IoQrCode size={20} />,
      id: 'qrcodes',
      title: 'QR Codes',
      url: '/qrcodes',
    },
    templates: {
      icon: <LuLayoutTemplate size={20} />,
      id: 'templates',
      title: 'Templates',
      url: '/templates',
    },
    documentation: {
      icon: <SlDocs size={20} />,
      id: 'documentation',
      title: 'Documentação',
      url: '/docs',
    }
  };
  
  

  export default NAVBARITEMS;
