export default function formatLocaleDateString(date, options) {
  return new Date(date).toLocaleDateString('pt-BR', options);
}


export function getMonthName({monthNumber}) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Mês inválido";
  }
}