import FeatherIcon from 'feather-icons-react';
import { Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import Avatar from '../components/Avatar/Avatar';
import { Link } from 'react-router-dom';
import { listAllOrganizationsService } from '../Services/organizationService';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { setItemStorage } from '../utils/storage';

export default function SwitchOrganizationPage() {

  const [companies, setCompanies] = useState([]);


  
  const getCompanies = async () =>{


   const response = await listAllOrganizationsService();

    // console.log(response)
   switch (response.statusCode) {
    case 200:
      // toast.success("Organização criada com sucesso", {position: "top-right",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: "light"});
      setCompanies(response.response.organizations)
    
      break;
    default:
      toast.error("temporarily offline", {position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"});
      break
  }
  } 

  useEffect(() => {
    getCompanies();
  
  }, [])


  
  


  const dropdown = (
    <Dropdown align="end" className="card-dropdown">
      <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
        <FeatherIcon icon="more-vertical" size="17" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>Excluir</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const handleClick = (item) => {
    setItemStorage({key: "organizationLogo", value: item.logoId});
    setItemStorage({key: "companyId", value: item.id});
  }

  const items = companies.map((item, index) => (
    <Col xs={12} lg={5} xl={4} md={6} key={index} >
        <Card style={{minHeight: "320px"}}>

        <Card.Body className="text-center">
          {/* {dropdown} */}
          <Link onClick={() => handleClick(item.id)} to={"/home"}>
            <Avatar as="a" size="lg" className="card-avatar mx-auto">
              <Avatar.Image src={item.logoId} className="rounded" alt={item.title} />
            </Avatar>
          </Link>
          <Card.Title as="h2">
          <Link onClick={() => handleClick(item)} to={"/"}>
              <a>{item.name}</a>
            </Link>
          </Card.Title>
          <Card.Text className="text-muted">{item.description}</Card.Text>
          <Card.Text className="text-muted">{item.email}</Card.Text>
        </Card.Body>
        <Card.Footer>
            <Link onClick={() => handleClick(item)} to={"/"}>
          <Row className="align-items-center">
            <Col>
              <Card.Text className="medium text-muted">
                <FeatherIcon icon="briefcase" size="1.2em" /> Entrar como {item.name}
              </Card.Text>
            </Col>
            <Col xs="auto">
            <FeatherIcon icon="arrow-right" size="1.4em" />
    
            </Col>
          </Row>
          </Link>
        </Card.Footer>
      </Card>
    </Col>
  ));

  return (
    <div className="main-content">
      {/* <ProfileHeader /> */}
      <Container fluid style={{padding: "50px"}}>
        <Row>{items}</Row>
      </Container>
      {/* <ToastContainer/> */}
    </div>
  );
}
