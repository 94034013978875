import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getItemStorage } from '../utils/storage';
import { checkAuth } from '../Services/userService';
import { useRefreshToken } from '../Services/refreshToken';

export const ProtectedRoute  = ({children}) => {


    let navigate = useNavigate();

    const logout =  () =>{
        localStorage.clear();
        navigate("/login");
    }


    const isAuth = async () =>{
        
        if (getItemStorage({key: "@QRConnect:Prod:At"}) != null){

        //    const response = await checkAuth();

        //    if (response.statusCode != 204){
        //         await useRefreshToken();
        //         logout();
        //     }        

        }else{
            logout();
        }

    }



    useEffect(() => {
        isAuth();
    }, [])

      return (
        <>{getItemStorage({key: "@QRConnect:Prod:At"}) != null ?  children : <Navigate to={"/login"}/> }</>
      )
      
    };

