import { listTemplatesService } from "../../Services/templateService";
import { getItemStorage } from "../../utils/storage";


export const updateCacheTemplates = (newData) => ({
    type: 'UPDATE_CACHE_TEMPLATES',
    payload: newData
  });
  


export const updateDataCacheTemplates = async ({dispatch, data =null}) => {
  if (data == null){
    const { response, statusCode } = await listTemplatesService({organizationId: getItemStorage({key: "companyId"})});

    
    if (statusCode == 200 || statusCode == 201) {

      dispatch(updateCacheTemplates(response.templates));
      return response.IAs
    }
  }else{

    dispatch(updateCacheTemplates(data))
    return data
  }
  
};



export const cacheReducerTemplates = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_CACHE_TEMPLATES':
      return action.payload;
    default:
      return state;
  }
};