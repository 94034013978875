import className from 'classnames';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
// import { IndeterminateCheckbox } from '../components/vendor';
import formatLocaleDateString from '../../utils/dateUtils';
import Sidenav from '../../components/SideNav/SideNav';
import { getItemStorage } from '../../utils/storage';
import { listTemplatesService } from '../../Services/templateService';
import { switchTypeCode, switchTypeTicket } from '../../utils/ticketUtils';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataCacheTemplates } from '../../redux/Templates';
import { Oval } from "react-loader-spinner";

export default function ListTemplatesPage() {

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);



  const TemplatesInCache = useSelector((state) => state.cahceTemplates || null);


  const data = useMemo(() => tableData, [tableData]);

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <Link to={"/template/view/" + row.original.id} title='Veja todas as Informações desse template'>
                    <FeatherIcon icon="eye" size="20" />
            </Link>
            {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
          </div>
        ),
      },
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: 'Nome',
        accessor: 'templateName',
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: 'Nome do emissor',
        accessor: 'issuerName',
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: 'Tipo de código',
        accessor: 'typeCode',
        Cell: ({ value }) => switchTypeCode({number: value})

      },
      {
        Header: 'Tipo de template',
        accessor: 'typeTemplate',
        Cell: ({ value }) => switchTypeTicket({number: value}),
      },
      {
        Header: 'Background',
        accessor: 'backgroundColor',
      },
      {
        Header: 'Criado',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <time dateTime={value}>
            {formatLocaleDateString(value, {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit',
            })}
          </time>
        ),
      },

    ],
    []
  );

  const { getTableBodyProps, headerGroups, page, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  

 const  listTemplates = async () =>{

    const {response, statusCode} = await listTemplatesService({organizationId: getItemStorage({key: "companyId"})})

    switch (statusCode) {
        case 200:

       
         
        await updateDataCacheTemplates({ dispatch: dispatch, data: response.templates })
        
        
        setTableData(response.templates)   
        
        


        
          break;
        case 409:
        //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
         break;
        default:
        //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }


 }
  useEffect(() => {


    if (TemplatesInCache != null){
        setTableData(TemplatesInCache);
    }
       
    
    listTemplates();
    
  }, [])





  useEffect(() => {
    if (TemplatesInCache != null){
      setTableData(TemplatesInCache);
  }

  }, [TemplatesInCache]);
  
  

  return (
    <>



    <Sidenav/>

    <div className="main-content">

    {TemplatesInCache === null ? 
    

                                      <div
                                                  style={{
                                                  height: "100vh",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  flexDirection: "column",
                                                  }}
                                              >
                                      
                                      <Oval
                                                visible={true}
                                                height="80"
                                                width="80"
                                                color="#2C7BE5"
                                                secondaryColor="#95AAC9"

                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                              />

                                  </div>
            
            
            : 



      <Container fluid style={{padding: "50px"}}>
      <Row className="justify-content-end">
        <Col xs="auto">
        <Button onClick={() => navigate("/template/register")}>Criar um template</Button>
        </Col>
      </Row>
        <Row className="justify-content-center" style={{marginTop: "15px"}}>
          <Col xs={12}>
            <Card>
              <Card.Header>
                <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                  <Form.Control
                    type="search"
                    placeholder="Procurar"
                    onChange={(e) => setGlobalFilter(e.target.value ? e.target.value : undefined)}
                  />
                  <InputGroup.Text>
                    <FeatherIcon icon="search" size="1em" />
                  </InputGroup.Text>
                </InputGroup>
                {/* <Dropdown align="right">
                  <Dropdown.Toggle variant="white" size="sm">
                    Bulk action
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#!">Action</Dropdown.Item>
                    <Dropdown.Item href="#!">Another action</Dropdown.Item>
                    <Dropdown.Item href="#!">Something else here</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </Card.Header>
              <div className='table-responsive'>

              <Table size="xs" className="card-table table-nowrap">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps({
                        
                        role: null,
                      })}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              className: className(column.className, column.canSort && 'is-sortable'),
                              role: null,
                            })
                          )
                
                        }
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps({
                    role: null,
                  })}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps({
                          role: null,
                        })}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.className,
                                role: null,
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

}
</div>

    </>
  );
}

