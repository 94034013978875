import { Col, Container, Row } from 'react-bootstrap';

import QuantityQRCode from '../components/Cards/quantityQrCode';
import Sidenav from '../components/SideNav/SideNav';
import QuantityQRCodePlayStore from '../components/Cards/quantityQrCodeGoogle';
import QuantityQRCodeApple from '../components/Cards/quantityQrCodeApple';
import { getItemStorage } from '../utils/storage';
import QuantityTemplates from '../components/Cards/quantityTemplates';
import { treeLoadAllDataCache } from '../redux/treeRedux';
import { useDispatch } from 'react-redux';

export default function MainPage() {
 
  const companyId = getItemStorage({key:"companyId"});


  const dispatch = useDispatch();
  console.log(companyId)



  treeLoadAllDataCache({ dispatch: dispatch });

  return (

    <>
    {/* <organizationContext.Provider> */}


    <Sidenav/>
    <div className="main-content">
        <Container fluid style={{padding: "50px"}}>

        <Row>
          <Col xs={12} md={6} xl>
            <QuantityTemplates />
          </Col>
          <Col xs={12} md={6} xl>
            <QuantityQRCode />
          </Col>
          <Col xs={12} md={6} xl>
            <QuantityQRCodePlayStore />
          </Col>
          <Col xs={12} md={6} xl>
            <QuantityQRCodeApple />
          </Col>

        </Row>


        </Container>


    </div>
        {/* </organizationContext.Provider> */}

    </>
    
  );
}
