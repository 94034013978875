import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { authUserService, checkUserInOrganizationService } from '../../Services/userService';
import { ToastContainer, toast } from 'react-toastify';
import { setItemStorage } from '../../utils/storage';
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import { treeLoadAllDataCache } from '../../redux/treeRedux';


export default function LoginForm() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  let navigate = useNavigate();
  const dispatch = useDispatch();



  const authUser = async () =>{
    const id = toast.loading('Autenticando... ⏳', {
      position: "top-right",
      });

    const response = await authUserService({email: email, password: password});
    switch (response.statusCode) {
      case 200:
        toast.update(id, {render: "Autenticado com sucesso", type: "success", isLoading: false, autoClose: 2000});

        setItemStorage({
          key: "@QRConnect:Prod:At",
          value: response.response.accessToken,
        });
        setItemStorage({
          key: "@QRConnect:Prod:Rt",
          value: response.response.refreshToken,
        });

        const decoded = jwtDecode(response.response.accessToken);


        treeLoadAllDataCache({ dispatch: dispatch });
        
        setItemStorage({ key: "userName", value: decoded.name });
        setItemStorage({ key: "organizationLogo", value: decoded.organizationLogo });
        setItemStorage({key: "companyId",value: decoded.organizationId })

        const inOrganization = await checkUserInOrganizationService();


        if (!inOrganization.response.inOrganization)
            setTimeout(function() {
                navigate("/organization/register");
            }, 1000);
        else{
          setTimeout(function() {
            navigate("/");
        }, 1000);
        }


        break;
      case 401:
       toast.update(id, {render: "E-mail ou senha inválidos", type: "warning", isLoading: false, autoClose: 4000});
       break;
      default:
        toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
        break;
    }

  }


  useEffect(() => {
    console.log(email)
    console.log(email !== "" && password !== "")
    if (email !== "" && password !== ""){
      setFormIsValid(true)
    }else{
      setFormIsValid(false);
    }
  }, [email, password])

  return (
    <>
      <h1 className="display-4 text-center mb-3">Login</h1>
      <p className="text-muted text-center mb-5">Acesse seu dashboard.</p>
      <form>
        <div className="form-group">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" placeholder="nome@email.com" onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className="form-group">
          <Row>
            <Col>
              <Form.Label>Senha</Form.Label>
            </Col>
          </Row>
          <InputGroup className="input-group-merge">
            <Form.Control type={hidePassword ? "password" : "text"} placeholder="Digite sua senha" onChange={(e) => setPassword(e.target.value)} />
            <InputGroup.Text>
              <FeatherIcon icon="eye" size="1em" onClick={()=> setHidePassword(!hidePassword)}/>
            </InputGroup.Text>
          </InputGroup>
          <Col xs="auto" style={{alignItems: "right"}}>
              <Link to="/password-reset">
                <Form.Text as="a" className="small text-muted">
                  Esqueceu a senha ?
                </Form.Text>
              </Link>
            </Col>
        </div>
       
        <Button size="lg" className="w-100 mb-3" disabled={!formIsValid} onClick={authUser}>
          Entrar
        </Button>
        <Link to="/signup">
        <p className="text-center">
          <small className="text-muted text-center">
            Ainda não possui conta? 
           
              <a> Clique aqui</a>
            
            .
          </small>
        </p>
        </Link>
      </form>
      
    </>
  );
}
