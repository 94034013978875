import { Col, Container, Row } from 'react-bootstrap';
import LoginForm from '../components/LoginForm/loginForm';
import peopleQRCode from "../assets/img/illustrations/peopleQRCode.png"

export default function LoginPage() {
  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={5} className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-md-block">
            <div className="text-center">
              <img className="img-fluid" src={peopleQRCode} alt="Pessoas utilizando QRCode, imagem do login inicial" yar />
            </div>
          </Col>
          <Col xs={12} md={6} xl={4} className="order-md-1 my-5">
            <LoginForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
