import React, {  } from 'react';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import { getMonthName } from '../../utils/dateUtils';
import { getContrastColor } from '../../utils/color';
import ButtonsSaveInWallet from '../ButtonsSaveInWallet';
import { TicketCodeZone, TicketContainer, TicketDivider, TicketHeader, TicketHolder, TicketSection } from './styles';
import { ArrowRight } from 'feather-icons-react/build/IconComponents';
import { IoMdDownload } from "react-icons/io";
import generatePDF from 'react-to-pdf';
import { PDF_OPTION } from '../../Resources/pdf';
import { ToastContainer, toast } from 'react-toastify';

const TransportTicket = ({typeCode, issuerName, companyLogo,
  codeValue, metadata,
  origin,
  destination,
  originDate,
  destinationDate,
  ticketNumber,
  passenger,
  backgroundColor,
  googleJwt,
  originCode,
  destinationCode,
  platform,
  seat,
  isExtern=false,
}) => {

      const sectionInfoStyle = {
        // display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
        paddingTop: '5px',
      };
    
      const airlineCodeStyle = {
        fontSize: '17px',
        color: getContrastColor({background: backgroundColor}),
        marginBottom: '16px',
        fontWeight: "bold"
      };
    

      const passengerTitleStyle = {
        fontWeight: '500',
        // marginBottom: '4px',
        color: getContrastColor({background: backgroundColor})
      };
    
      const passengerInfoStyle = {
        fontSize: '18px',
        color: getContrastColor({background: backgroundColor}),
      };


      const buttonContainerStyle = {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"

      };

      


     




    const getTargetElement = () => document.getElementById('cardContainer');



     const filename = issuerName + "_" + origin.replace(" ", "_").replace(/ /g, "_")  + "_X_" + destination.replace(/ /g, "_") + "-" + passenger.replace(/ /g, "_") + '.pdf'

     const options = PDF_OPTION({fileName: filename})
  
      console.log(companyLogo)


      const donwloadPDF = () => {
    
        const id = toast.loading('Gerando PDF... ⏳', {
          position: "top-right",
          });
    
          generatePDF(getTargetElement, options)
    
          toast.update(id, {render: "PDF gerado com sucesso", type: "success", isLoading: false, autoClose: 2000});
        }
    
      return (
        <div >
        <TicketContainer backgroundColor={backgroundColor} id="cardContainer">
 

          <TicketHeader>
                  <img
                  src={companyLogo === null ? "https://placehold.co/512x512": typeof(companyLogo) === "string" ? companyLogo : URL.createObjectURL(companyLogo)}
                  alt={issuerName + " logo"}
                  style={{ height: '50px' }}
                  />
                  <h2 style={{ margin: 0, fontSize: '18px', fontWeight: '500', marginLeft: "15px", color: getContrastColor({ background: backgroundColor }) }}>{issuerName}</h2>
                  {isExtern &&  <div id="buttonDownload" style={{ marginLeft: "auto", cursor: "pointer" }} onClick={donwloadPDF}>
                    <IoMdDownload style={{ fontSize: "30px", marginRight: "10px", color: getContrastColor({ background: backgroundColor })}} />
                  </div>}
                
          </TicketHeader>
          <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

          {(origin !== "" || destination !== "") &&
          
                <TicketSection>
                    <span style={{ textWrap: "pretty",fontSize: '0.9rem',fontWeight: '500', 
                                   color: getContrastColor({background: backgroundColor}) }}>{origin} X {destination}</span>
                </TicketSection>

          }



          {((originCode !== ""|| destinationCode !== "") && (originCode !== null || destinationCode !== null)) &&
                 ( <div style={{ display: "flex", justifyContent: "left", alignItems: 'center', marginTop: "8px"}}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ fontSize: '28px', fontWeight: '500', margin: '0', color: getContrastColor({ background: backgroundColor }) }}>{originCode}</p>
                  </div>
                  <ArrowRight style={{marginLeft: "5px", color:  getContrastColor({ background: backgroundColor })}} />
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                      <p style={{ fontSize: '28px', fontWeight: '500', margin: '0', color: getContrastColor({ background: backgroundColor }) }}>{destinationCode}</p>
                  </div>
              </div>)
          }

         



          <TicketSection >
            <div style={{maxWidth: "160px" }}>
                {originDate !== null ?  
                        <> 
                            <p style={{ fontSize: '28px', fontWeight: '500', margin: '0', color: getContrastColor({background: backgroundColor}) }}>{originDate.getHours().toString() + ":" + originDate.getMinutes().toString().padStart(2, '0')}</p>
                             <p style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>{originDate.getDate().toString() + " de " + getMonthName({monthNumber: originDate.getMonth()+1}).toString() + " de " + originDate.getFullYear().toString()}</p>
                        </>: 
                        null
                }
              {/* <p style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}) }}>{origin}</p> */}
            </div>
            <div>
            </div>
            
            <div style={{maxWidth: "160px"}}>
                        {destinationDate !== null ? 
                        <> 
                            <p style={{ fontSize: '28px', fontWeight: '500', margin: '0', color:getContrastColor({background: backgroundColor}) }}>{destinationDate.getHours().toString() + ":" + destinationDate.getMinutes().toString().padStart(2, '0')}</p>
                            <p style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>{destinationDate.getDate().toString() + " de " + getMonthName({monthNumber: destinationDate.getMonth()+1}).toString() + " de " + destinationDate.getFullYear().toString()}</p>
                        </> : 
                        null
                        }
               {/* <p style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}) }}>{destination}</p>  */}
            </div>
          </TicketSection>
          <div>




          </div>


          <div style={sectionInfoStyle}>
                <span style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>Plataforma: {platform}</span>
                <br />
                <span style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>Poltrona: {seat}</span>
                
          </div>
          <div style={sectionInfoStyle}>
          {metadata.map((item, index) => (
                <React.Fragment key={index}>
                  {item.title !== "" ? <>
                  <span style={{ fontSize: '16px', color: getContrastColor({background: backgroundColor}) }}>{`${item.title}: ${item.value}`}</span>
                  <br />
                  </>: <></>}
                </React.Fragment>
              ))}
          </div>
          <TicketSection >
            <span style={airlineCodeStyle}>Número do bilhete </span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '20px', color: getContrastColor({background: backgroundColor}), fontWeight: 'bold' }}>{ticketNumber}</span>
              {/* <i className="fas fa-clipboard-list" style={{ color: getContrastColor({background: backgroundColor}), marginLeft: '8px' }}></i> */}
            </div>
          </TicketSection>


          <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>

          <TicketHolder>
            <span style={passengerTitleStyle}>Passageiro (a)</span>
            <div>
              <p style={passengerInfoStyle}>{passenger}</p>
              {/* <p style={{ fontSize: '12px', color: '#aaa' }}>20 kg., In-flight meal</p> */}
            </div>
          </TicketHolder>


          <TicketDivider backgroundColor={getContrastColor({background: backgroundColor})}/>
          
          <TicketCodeZone>
            
            {typeCode === 1 || typeCode === null ? 
                                  <div style={{ background: 'white', padding: '18px', borderRadius: "20px", width: "max-content" }}>
                                  <QRCode value={codeValue} size={200} /> </div>:  
                                  <Barcode value={codeValue} width={2.5} displayValue={false}/>
            }

          </TicketCodeZone>
     
          <div id='buttonZone' data-html2canvas-ignore="true">
                <div style={buttonContainerStyle}>
                              <ButtonsSaveInWallet googleJwt={googleJwt}/>
                </div>
          </div>

      
      </TicketContainer>
      

      </div>

      );
    };

export default TransportTicket;
