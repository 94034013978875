import React, { useContext, useEffect, useState } from "react";
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { FaInfoCircle } from "react-icons/fa";
import { CiCirclePlus, CiCircleRemove } from "react-icons/ci";
import reactTextareaAutosize from "react-textarea-autosize";
import ReactSelect from "react-select";
import TransportTikcetForm from "./RegisterTransportForm";
import EventTikcetForm from "./RegisterEventForm";
import LoyaltyTikcetForm from "./RegisterLoyaltyForm";
import { qrFormContext } from "../../context/context";
import ReactInputMask from "react-input-mask";
import { isValidGoogleMapsLink, validEmail, validPhoneNumber } from "../../utils/stringUtils";
import { createTicketService } from "../../Services/ticketService";
import { ToastContainer, toast } from 'react-toastify';
import { getItemStorage } from "../../utils/storage";
import { useNavigate } from "react-router-dom";


export default function RegisterTicketForm({isEditable}) {


  let navigate = useNavigate();
  const [typeTemplate, setTypeTemplate] = useState(null);

  // const [metadata, setMetadata] = useState([{ title: "", value: "" }]);
  const [holderNameIsInvalid, setHolderNameIsInvalid] = useState(null);
  const [ticketHolderName, setTicketHolderName] = useState(null);
  const [ticketNumberIsValid, setTicketNumberIsValid] = useState(null);
  const [contactPhoneNumberIsValid, setContactPhoneNumberIsValid] = useState(null);
  const [contactEmailIsValid, setContactEmailIsValid] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [qrCodeValueIsValid, setQrCodeValueIsValid] = useState(false);
  const [originNameIsValid, setOriginNameIsValid] = useState(null);
  const [originCodeIsValid, setOriginCodeIsValid] = useState(null);
  const [destinationNameIsValid, setDestinationNameIsValid] = useState(null);
  const [destinatioCodeIsValid, setDestinationCodeIsValid] = useState(null);
  const [originGoogleMapsLinkIsValid, setOriginGoogleMapsLinkIsValid] = useState(null);
  const [destinationGoogleMapsLinkIsValid, setDestinationGoogleMapsLinkIsValid] = useState(null)
  const [platformIsValid, setPlatformIsValid] = useState(null);
  const [seatIsValid, setSeatIsValid] = useState(null)
  const [priceIsValid, setPriceIsValid] = useState(null);
  const [eventLocalIsValid, setEventLocalIsValid] = useState(null);
  const [eventLocalMapsLinkIsValid, setEventLocalMapsLinkIsValid] =  useState(null);
  const [sectionIsValid, setSectionIsValid] =  useState(null);

  const { templates,
        template, setTemplate,
        holderName, setHolderName,
        ticketNumber, setTicketNumber,
        contactPhone, setContactPhone,
        contactEmail, setContactEmail,
        metadata, setMetadata,
        originName, setOriginName,
        originCode, setOriginCode,
        destinationName, setDestinationName,
        destinationCode, setDestinationCode,
        startDate, setStartDate,
        endDate, setEndDate,
        originGoogleMapsLink, setOriginGoogleMapsLink,
        destinationGoogleMapsLink, setDestinationGoogleMapsLink,
        platform, setPlatform,
        seat,setSeat,
        price, setPrice,
        eventLocal, setEventLocal,
        eventLocalMapsLink, setEventLocalMapsLink,
        gate, setGate,
        section, setSection,
        qrCodeValue, setQrCodeValue,
        row, setRow
    } = useContext(qrFormContext);


  const handleMetadataChange = (index, field, value) => {
    const updatedMetadata = [...metadata];
    updatedMetadata[index][field] = value;
    setMetadata(updatedMetadata);
  };

  const handleAddRow = () => {
    setMetadata([{ title: "", value: "" }, ...metadata]);
  };

  const handleRemoveRow = (index) => {
    const updatedMetadata = [...metadata];
    updatedMetadata.splice(index, 1);
    setMetadata(updatedMetadata);
  };

  const shouldShowCiCirclePlus = (item, index) => {
    return index === 0 && item.title.trim() !== "" && item.value.trim() !== "";
  };



  useEffect(() => {
    if (holderName === ""){
      setHolderNameIsInvalid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (holderName.length > 3){
        setHolderNameIsInvalid(true)
      }else{
        setHolderNameIsInvalid(false);
      }
    }
   
  }, [holderName])

  useEffect(() => {
    if (ticketNumber === ""){
      setTicketNumberIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (ticketNumber.length > 3){
        setTicketNumberIsValid(true)
      }else{
        setTicketNumberIsValid(false);
      }
    }
   
  }, [ticketNumber])


  useEffect(() => {

    if (contactPhone === ""){
      setContactPhoneNumberIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (validPhoneNumber({phoneNumber: contactPhone})){
        setContactPhoneNumberIsValid(true)
      }else{
        setContactPhoneNumberIsValid(false);
      }
    }
   
  }, [contactPhone])


  useEffect(() => {

    if (contactEmail === ""){
      setContactEmailIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (validEmail({email: contactEmail})){
        setContactEmailIsValid(true)
      }else{
        setContactEmailIsValid(false);
      }
    }
   
  }, [contactEmail])



  useEffect(() => {
    if (qrCodeValue === ""){
      setQrCodeValueIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (qrCodeValue.length > 3){
        setQrCodeValueIsValid(true)
      }else{
        setQrCodeValueIsValid(false);
      }
    }
   
  }, [qrCodeValue])



   

  useEffect(() => {

    if (originName === ""){
        setOriginNameIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (originName.length > 2){
        setOriginNameIsValid(true)
      }else{
        setOriginNameIsValid(false);
      }
    }
   
  }, [originName])


  useEffect(() => {

    if (destinationName === ""){
        setDestinationNameIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (destinationName.length > 2){
        setDestinationNameIsValid(true)
      }else{
        setDestinationNameIsValid(false);
      }
    }
   
  }, [destinationName])



  useEffect(() => {

    if (originCode === ""){
      setOriginCodeIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (originCode.length <4 && originCode.length > 1){
        setOriginCodeIsValid(true)
      }else{
        setOriginCodeIsValid(false);
      }
    }
   
  }, [originCode])


  useEffect(() => {

    if (destinationCode === ""){
      setDestinationCodeIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (destinationCode.length <4){
        setDestinationCodeIsValid(true)
      }else{
        setDestinationCodeIsValid(false);
      }
    }
   
  }, [destinationCode])


  useEffect(() => {
    if (originGoogleMapsLink === ""){
      setOriginGoogleMapsLinkIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (isValidGoogleMapsLink({link: originGoogleMapsLink})){
        setOriginGoogleMapsLinkIsValid(true)
      }else{
        setOriginGoogleMapsLinkIsValid(false);
      }
    }
   
  }, [originGoogleMapsLink])



  useEffect(() => {

    if (destinationGoogleMapsLink === ""){
      setDestinationGoogleMapsLinkIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (isValidGoogleMapsLink({link: destinationGoogleMapsLink}) && destinationGoogleMapsLink != originGoogleMapsLink ){
        setDestinationGoogleMapsLinkIsValid(true)
      }else{
        setDestinationGoogleMapsLinkIsValid(false);
      }
    }
   
  }, [destinationGoogleMapsLink, originGoogleMapsLink])



  useEffect(() => {

    if (platform === ""){
      setPlatformIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (platform.length < 4){
        setPlatformIsValid(true)
      }else{
        setPlatformIsValid(false);
      }
    }
   
  }, [platform])




  useEffect(() => {
    console.log(price === "")
    if (price === ""){
        setPriceIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (parseFloat(price.replace("R$ ", "").replace(",", ".")) >= 0){
        setPriceIsValid(true)
      }else{
        setPriceIsValid(false);
      }
    }
   
  }, [price])



  useEffect(() => {

    if (eventLocal === ""){
            setEventLocalIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (eventLocal.length > 3){
            setEventLocalIsValid(true)
      }else{
            setEventLocalIsValid(false);
      }
    }
   
  }, [eventLocal]);


  useEffect(() => {
    if (eventLocalMapsLink === ""){
            setEventLocalMapsLinkIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (isValidGoogleMapsLink({link: eventLocalMapsLink})){
            setEventLocalMapsLinkIsValid(true)
      }else{
            setEventLocalMapsLinkIsValid(false);
      }
    }
   
  }, [eventLocalMapsLink])


  useEffect(() => {
    if (seat === ""){
            setSeatIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (seat.length < 8){
          setSeatIsValid(true)
      }else{
        setSeatIsValid(false);
      }
    }
   
  }, [seat])

  useEffect(() => {
    if (section === ""){
            setSectionIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (section.length < 8){
        setSectionIsValid(true)
      }else{
        setSectionIsValid(false);
      }
    }
   
  }, [section])


  useEffect(() => {
    if (section === ""){
            setSectionIsValid(null);
    }else{
      // if (isEditable == false)
      //   return
      if (section.length < 8){
        setSectionIsValid(true)
      }else{
        setSectionIsValid(false);
      }
    }
   
  }, [section])


  useEffect(() => {


    if (template == null)
      return
    switch (template.typeTemplate) {
      case 1:
        if (holderNameIsInvalid && contactPhoneNumberIsValid && contactEmailIsValid && qrCodeValueIsValid && ticketNumberIsValid 
            && originNameIsValid &&
            startDate != null && endDate != null && originGoogleMapsLinkIsValid && destinationNameIsValid && platformIsValid && seatIsValid && 
            price != ""
            ){
          setFormIsValid(true);
        }else{
          setFormIsValid(false);
  
        }
        break;
      case 2:
        // console.log(priceIsValid)
        if (holderNameIsInvalid && contactPhoneNumberIsValid && contactEmailIsValid && qrCodeValueIsValid && ticketNumberIsValid && eventLocalIsValid && eventLocalMapsLinkIsValid && priceIsValid){
          setFormIsValid(true);
        }else{
          setFormIsValid(false);
  
        }
        break;
      case 3:

      if (holderNameIsInvalid && contactPhoneNumberIsValid && contactEmailIsValid && qrCodeValueIsValid){
        setFormIsValid(true);
      }else{
        setFormIsValid(false);

      }

        break
    
      default:
        break;
    }
    // if (template.typeTemplate --){

    // }
    //   setFormIsValid(true)
   
    }, [template, qrCodeValueIsValid, holderNameIsInvalid, contactPhoneNumberIsValid, contactEmailIsValid, ticketNumberIsValid, 
      originNameIsValid, startDate, endDate, originGoogleMapsLink, destinationNameIsValid, platformIsValid, seatIsValid, price,
      eventLocalIsValid, eventLocalMapsLinkIsValid, priceIsValid])

  


  
  const whatForm = () => {

    switch (typeTemplate) {
      case 1:
        return <TransportTikcetForm ticketNumber={ticketNumber} setTicketNumber={setTicketNumber} ticketNumberIsValid={ticketNumberIsValid}
                originName={originName} originCode={originCode} setOriginName={setOriginName} setOriginCode={setOriginCode}
                destinationName={destinationName} setDestinationName={setDestinationName}
                destinationCode={destinationCode} setDestinationCode={setDestinationCode}
                startDate={startDate} setStartDate={setStartDate}
                endDate={endDate} setEndDate={setEndDate}
                originGoogleMapsLink={originGoogleMapsLink} 
                setOriginGoogleMapsLink={setOriginGoogleMapsLink}
                destinationGoogleMapsLink={destinationGoogleMapsLink} setDestinationGoogleMapsLink={setDestinationGoogleMapsLink}
                platform={platform} setPlatform={setPlatform}
                seat={seat} setSeat={setSeat}
                price={price} setPrice={setPrice}
                originNameIsValid={originNameIsValid} 
                originCodeIsValid={originCodeIsValid} 
                destinationNameIsValid={destinationNameIsValid} 
                destinatioCodeIsValid={destinatioCodeIsValid} 
                originGoogleMapsLinkIsValid={originGoogleMapsLinkIsValid} 
                destinationGoogleMapsLinkIsValid={destinationGoogleMapsLinkIsValid} 
                platformIsValid={platformIsValid} 
                seatIsValid={seatIsValid} 
                isEditable={isEditable}

                />
      case 2:
        return <EventTikcetForm ticketNumber={ticketNumber} setTicketNumber={setTicketNumber} ticketNumberIsValid={ticketNumberIsValid}
                  eventLocal={eventLocal} setEventLocal={setEventLocal} 
                  eventLocalMapsLink={eventLocalMapsLink} setEventLocalMapsLink={setEventLocalMapsLink}
                  price={price} setPrice={setPrice}
                  seat={seat} setSeat={setSeat}
                  gate={gate} setGate={setGate}
                  section={section}  setSection={setSection}
                  eventLocalIsValid={eventLocalIsValid}setEventLocalIsValid={setEventLocalIsValid}
                  eventLocalMapsLinkIsValid={eventLocalMapsLinkIsValid} setEventLocalMapsLinkIsValid={setEventLocalMapsLinkIsValid}
                  priceIsValid={priceIsValid} row={row} setRow={setRow}
                  isEditable={isEditable}
                  
                  />
      case 3:
        return <LoyaltyTikcetForm isEditable={isEditable}/>
      default:
        return <></>;
    }
  }


  useEffect(() => {
    if (template!=null){
      setTypeTemplate(template.typeTemplate)
    }
   
  }, [section])


  const createTicket = async () => {

    const id = toast.loading('Emitindo ticket... ⏳', {
      position: "top-right",
      theme: "light"
      });
      console.log(eventLocal)
      console.log("xxxxxxxxxxx")
    const response = await createTicketService({ holderName: holderName, email: contactEmail, metadata: metadata, phoneNumber: contactPhone,
      qrCodeValue: qrCodeValue, templateId: template.id,
      startDate: startDate, endDate : endDate, seat:seat, platform: platform, price: parseFloat(price.replace("R$ ", "").replace(",", ".")), originName: originName,
       originCode: originCode, destinationName: destinationName, destinationCode: destinationCode, originMapsLink: originGoogleMapsLink, 
       destinationMapsLink: destinationGoogleMapsLink, ticketNumber: ticketNumber, eventLocalName: eventLocal, eventLocalMapsLink: eventLocalMapsLink,
       gate: gate, row: row, section: section,
      organizationId: getItemStorage({key: "companyId"})})
      

      switch (response.statusCode) {
        case 201:
          toast.update(id, {render: "Ticket emitido com sucesso", type: "success", isLoading: false,  autoClose: 2000});

          setTimeout(function() {
            navigate("/qrcodes");
        }, 1000);
          break;
        case 409:
          toast.update(id, {render: response.response.message, type: "warning", isLoading: false, autoClose: 2000});
        //   setTimeout(function() {
        //     navigate("/");
        // }, 2000);
          break;


        default:
          toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }
  

  }


  return (
    <>
      <h1 className="display-4 text-center mb-3">Emissão de ticket</h1>
      <p className="text-muted text-center mb-5">Emita um ticket baseado em um template escolhido</p>


      {isEditable &&
      <div className="form-group">
        
        <Form.Label>Template do ticket{" "}

        <FaInfoCircle
              style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
              title={
               
                        "Escolha o modelo do ticket que melhor se encaixa no seu negócio:\n" +
                        "- Bilhete de transporte: ideal para transporte, como ônibus, trens e voos ;\n" +
                        "- Ingresso de evento: perfeito para cinemas, festas, shows e eventos em geral;\n" +
                        "- Cartão fidelidade: recomendado para cartões de fidelidade."
                    }
          
            />
        </Form.Label>
        
        
        <ReactSelect
          options={templates}
          placeholder={"Selecione um dos seus templates"}
          onChange={(e) => {setTypeTemplate(e.typeTemplate); setTemplate(e.template)}}

        />
        
       
        </div>
        }
      {typeTemplate && (
        <>



            <div className="form-group">
                        <Form.Label>
                            Nome do portador<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Nome do portador, aparecerá no ticket"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Nome do portador" onChange={(e) => setHolderName(e.target.value)} 
                        disabled={!isEditable}
                        value={holderName} 
                        isInvalid={holderNameIsInvalid == null ? false : !holderNameIsInvalid}
                        isValid={holderNameIsInvalid == null ? false : holderNameIsInvalid}/>
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
                        {/* <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback> */}
            </div>

            <div className="form-group">
                        <Form.Label>
                            Telefone de contato para o cliente<span style={{ color: 'red' }}>*</span>{' '}
                            
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="Telefone que a pessoa poderá entrar em contato para algum tipo de suporte"
                                />
                        </Form.Label>
                        
                        <Form.Control required as={ReactInputMask} placeholder="(24) 99281-6037" onChange={(e) => setContactPhone(e.target.value)} 
                        disabled={!isEditable}
                        value={contactPhone} mask="(99) 99999-9999" 
                        isInvalid={contactPhoneNumberIsValid == null ? false : !contactPhoneNumberIsValid} 
                        isValid={contactPhoneNumberIsValid == null ? false : contactPhoneNumberIsValid} />
                        <Form.Control.Feedback type="invalid">Telefone inválido</Form.Control.Feedback>
                        
            </div>
            <div className="form-group">
                        <Form.Label>
                            E-mail de contato para o cliente<span style={{ color: 'red' }}>*</span>{' '}
                                <FaInfoCircle
                                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                title="E-mail que a pessoa poderá entrar em contato para algum tipo de suporte"
                                />
                        </Form.Label>
                        <Form.Control required type="text" placeholder="contato@minhaempresa.com.br" onChange={(e) => setContactEmail(e.target.value)} 
                        value={contactEmail} 
                        disabled={!isEditable}
                        isInvalid={contactEmailIsValid == null ? false : !contactEmailIsValid}
                        isValid={contactEmailIsValid == null ? false : contactEmailIsValid} />
                        <Form.Control.Feedback type="invalid">E-mail inválido</Form.Control.Feedback>
            </div>

            {whatForm()}
          <div className="form-group">
            <Form.Label>
              Metadata{' '}
              <FaInfoCircle
                style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                title="E-mail que a pessoa poderá entrar em contato para algum tipo de suporte"
              />
            </Form.Label>
            <Container style={{marginLeft: "5px"}}>
              {metadata.map((item, index) => (
                <Row className="align-items-center mb-3" key={index}>
                  <Col style={{minWidth: "60%", paddingLeft: 0  }}>
                    <Form.Control
                      type="text"
                      placeholder="Título"
                      value={item.title}
                      disabled={!isEditable}
                      onChange={(e) => handleMetadataChange(index, "title", e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col style={{minWidth: "30%", paddingLeft: 0 }}>
                    <Form.Control
                      type="text"
                      placeholder="Valor"
                      value={item.value}
                      disabled={!isEditable}
                      onChange={(e) => handleMetadataChange(index, "value", e.target.value)}
                      style={{width: "100%" }}
                    />
                  </Col>
                  {isEditable && 
                  
                  <Col style={{minWidth: "10%", paddingLeft: 0 }}>
                  {shouldShowCiCirclePlus(item, index) && (
                    <CiCirclePlus
                      style={{ marginLeft: '1px', color: '#007bff', cursor: 'pointer', fontSize: "30px" }}
                      // title="Nome do portador, aparecerá no ticket"
                      onClick={handleAddRow}
                    />
                  )}
                  {metadata.length > 1 && <CiCircleRemove
                    style={{ marginLeft: '1px', color: 'red', cursor: 'pointer', fontSize: "30px" }}
                    title="Remover linha"
                    onClick={() => handleRemoveRow(index)}
                  />}
                  
                </Col>
                  }

                </Row>
              ))}
            </Container>
          </div>
            

          <div className="form-group">
                       
                        <Form.Label>
                                      Informação do QR Code<span style={{ color: 'red' }}>*</span>{' '}
                                      <FaInfoCircle
                                      style={{ marginLeft: '5px', color: '#007bff', cursor: 'pointer' }}
                                      title="Informação do QR Code (obrigatório)- conteúdo incorporado no código QR."
                                      />
                        </Form.Label>
                        
                        <Form.Control
                                    required
                                    as={reactTextareaAutosize}
                                    // className="form-control-auto form-control-flush"
                                    maxRows={6}
                                    minRows={4}
                                    cacheMeasurements={true}
                                    value={qrCodeValue}
                                    disabled={!isEditable}
                                    placeholder="Conteúdo do QRCODE"
                                    isInvalid={qrCodeValueIsValid === null ? false : !qrCodeValueIsValid}
                                    isValid={qrCodeValueIsValid === null ? false : qrCodeValueIsValid}
                                    onChange={(e) => setQrCodeValue(e.target.value)}
                                />{' '}
                        <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
            </div>
            {isEditable != false && <Button size="lg" className="w-100 mb-3" onClick={createTicket} disabled={!formIsValid}>
          Emitir
        </Button>}
            
        </>
      )}
      {/* <ToastContainer/> */}
    </>
  );
}
