import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getQuantityTemplatesByOrganizationService } from '../../Services/templateService';
import { getItemStorage } from '../../utils/storage';
import { LuLayoutTemplate } from "react-icons/lu";

export default function QuantityTemplates() {

  const [quantity, setQuantity] = useState(0);
  const data = useMemo(() => quantity, [quantity]);



  const getQuantityTemplatesByOrganization = async () => {

    const response = await getQuantityTemplatesByOrganizationService({organizationId: getItemStorage({key: "companyId"})})


    switch (response.statusCode) {
      case 200:
        
        setQuantity(response.response.quantity);
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    
    getQuantityTemplatesByOrganization();
    
  }, [])
  
  return (
    <Card style={{minHeight: "144px"}}>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Quantidade de templates criados</h6>
            <span className="h2 mb-0">{data}</span>
          </Col>
          <Col xs="auto">
            <LuLayoutTemplate size={30} />

            {/* <FeatherIcon icon="codepen" size="20" className="text-muted" /> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
