import { Provider } from 'react-redux';
// import './App.css';
import RoutesA from './routes';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/rootRedux';
function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>

            <RoutesA/>

            </Provider>
    </BrowserRouter>
    // <div className="App">

    //   <InvoicePage />
     
    // </div>
  );
}

export default App;
