import styled from 'styled-components';

export const TicketContainer = styled.div`
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
`;


export const TicketHeader = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 16px;
`;


export const TicketDivider = styled.div`
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-style: dashed dashed dashed dashed;
  border-top: 2px dashed ${props => props.backgroundColor};

`;


export const TicketSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 8px; */
    padding-top: 16px;
    

`;



export const TicketHolder = styled.div`
    padding-top: 16px;
`;


export const TicketCodeZone = styled.div`
    text-align: center;
    margin-bottom: 16px;
    padding-top: 16px;
    display: flex;
    justify-content: center;
`;


export const Arrow = styled.div`
    /* Adicione estilos para a seta aqui */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid #333; /* Cor da seta */
`;