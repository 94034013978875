import { Link, useParams } from "react-router-dom";
import RegisterTicketPage from "./QRCodeRegisterPage";
import { getTicketByIdService } from "../../Services/ticketService";
import { getItemStorage } from "../../utils/storage";
import RegisterTicketForm from "../../components/RegisterTicketForm/RegisterTicketForm";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { qrFormContext } from '../../context/context';
import LoyaltyCard from "../../components/qrTemplates/customerCard";
import EventTicket from "../../components/qrTemplates/eventTicket";
import TransportTicket from "../../components/qrTemplates/transportTicket";

export default function ViewTicketPage() {

    const {ticketId} = useParams();

    const [ticket, setTicket] = useState(null);

    const getTicketById = async  () =>{


        const response = await getTicketByIdService({ticketId: ticketId, organizationId: getItemStorage({key: "companyId"})})

        switch (response.statusCode) {
            case 200:
    
            //   toast.update(id, {render: "Usuário criado", type: "success", isLoading: false});
              
             console.log(response.response.ticket);
             setTicket(response.response.ticket);
            
    
    
              break;
            case 404:
            //  toast.update(id, {render: "E-mail já cadastrado", type: "warning", isLoading: false, autoClose: 4000});
             break;
            default:
            //   toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
              break;
    }
}


const TemplateTicket = () =>{

        
    switch (ticket.template.typeTemplate) {
      case 1:
        return <TransportTicket typeCode={ticket.template.typeCode} 
                                issuerName={ticket.template.issuerName}
                                codeValue={ticket.qrCodeValue}
                                metadata={ticket.metadata}
                                companyLogo={ticket.template.logoUrl}
                                origin={ticket.originName}
                                destination={ticket.destinationName}
                                originDate={new Date(ticket.timeInterval.startDate)}
                                destinationDate={new Date(ticket.timeInterval.endDate)}
                                ticketNumber={ticket.ticketNumber}
                                passenger={ticket.holderName}
                                backgroundColor={ticket.template.backgroundColor}
                                googleJwt={ticket.jwt}
                                originCode={ticket.originCode}
                                destinationCode={ticket.destinationCode}
                                platform={ticket.platform}
                                seat={ticket.seat}
                                />
                                break;

    case 2:
      console.log(ticket);
       return  <EventTicket issuerName={ticket.template.eventName} 
                     eventName={ticket.template.eventName} 
                      eventDate={ new Date(ticket.template.timeInterval.startDate)}
                      metadata={ticket.metadata}
                      identifier={ticket.ticketNumber}
                      userName={ticket.holderName}
                      companyLogo={ticket.template.logoUrl}
                      backgroundColor={ticket.template.backgroundColor}
                      typeCode={ticket.template.typeCode}
                      googleJwt={ticket.jwt}
                      codeValue={ticket.qrCodeValue}
                      gate={ticket.gate}
                      section={ticket.section}
                      row={ticket.row}
                      seat={ticket.seat}
        />
    case 3:
        return <LoyaltyCard issuerName={ticket.template.issuerName} 
                            backgroundColor={ticket.template.backgroundColor}
                            promoName={ticket.template.promoName}
                            metadata={ticket.metadata}
                            userName={ticket.holderName}
                            companyLogo={ticket.template.logoUrl}
                            codeValue={ticket.qrCodeValue}
                            typeCode={ticket.template.typeCode}
                            googleJwt={ticket.jwt}
                            nameLabel={ticket.template.nameLabel}
                            />


      default:
        
        break;
    }
  }


useEffect(() => {
        
    getTicketById();
    
}, [])


return (
    <>
    {
            ticket != null ? (
    <qrFormContext.Provider value={{ template: ticket.template,
                                      holderName: ticket.holderName,
                                      ticketNumber: ticket.ticketNumber == undefined ? "": ticket.ticketNumber,
                                      contactPhone: ticket.phoneNumber,
                                      contactEmail: ticket.email,
                                      metadata: ticket.metadata,
                                      originName: ticket.originName == undefined ? "": ticket.originName,
                                      originCode: ticket.originCode == undefined ? "": ticket.originCode,
                                    //   startDate, setStartDate,
                                    //   endDate, setEndDate,
                                      originGoogleMapsLink: ticket.originMapsLink == undefined ? "": ticket.originMapsLink,
                                      destinationGoogleMapsLink: ticket.destinationMapsLink == undefined ? "": ticket.destinationMapsLink,
                                      destinationName: ticket.destinationName == undefined ? "": ticket.destinationName,
                                      destinationCode: ticket.destinationCode == undefined ? "": ticket.destinationCode,
                                        platform: ticket.platform == undefined  ? "": ticket.platform,
                                        seat: ticket.seat == undefined  ? "":  ticket.seat,
                                        price: ticket.price == undefined  ? "": ticket.price.toString(),
                                        eventLocal: ticket.eventLocalName == undefined  ? "": ticket.eventLocalName,
                                        eventLocalMapsLink: ticket.eventLocalMapsLink == undefined  ? "": ticket.eventLocalMapsLink,
                                        section: ticket.section == undefined  ? "":  ticket.section,

                                        gate: ticket.gate == undefined  ? "":  ticket.gate,

                                        row: ticket.row == undefined  ? "":  ticket.row,

                                        seat: ticket.seat == undefined  ? "":  ticket.seat,
                                        promoName: ticket.template.promoName == undefined  ? "":  ticket.template.promoName,
                                        nameLabel:ticket.template.nameLabel == undefined  ? "":  ticket.template.nameLabel,
                                        qrCodeValue: ticket.qrCodeValue
                                      

                                  }}>


                                    <Link to={"/qrcodes"}>
                                        <IoIosArrowRoundBack style={{fontSize: "50px", marginLeft: "10px"}}/>
                                    </Link>
    <div className="d-flex align-items-center min-vh-100 bg-auth border-primary">
    <Container>
                <Row>

    

                <Col xs={12} md={6} className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 custom-style-for-type-0" 
                    style={{  
                        // position: "-webkit-sticky",
                        // position: "sticky",
                        // zIndex: 1000,
                        // backgroundColor: "pink",
                        
                        
                        }}>

                        <div style={{ position: "sticky", top: "5%", bottom: "8%"}}>
                            {TemplateTicket()}
                        </div>
                </Col>

 



                <Col xs={12} md={5} xl={4} className="order-md-1 my-5">
                        <RegisterTicketForm isEditable={false} />
                </Col>  
         </Row>
        </Container>
    </div>
    
    
    
    </qrFormContext.Provider>) : null}
    
    
    </>);



}