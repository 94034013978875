import axios from 'axios';

const api = axios.create({

  baseURL: process.env.REACT_APP_API_BASE_URL,


});

api.defaults.headers["Api-Key"] = process.env.REACT_APP_API_KEY



export default api;